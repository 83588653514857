import {
  selectBlogById,
  selectBlogLoadingAction,
  selectBlogLoadingById
} from 'app/features/blog/selectors';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  blogByIdApi,
  blogEdit,
  postBlog,
  deleteFileApi
} from 'app/features/blog/slice';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Upload,
  UploadFile,
  UploadProps
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useCustomRequestForBlogImage } from 'Hook/useCustomRequest';

import { convertToAbsoluteUrl } from '../../helpers/utils';
import { IBlogByIdValues } from '../../app/features/blog/types';
import Loading from 'components/Loading';
import DetailsHead from 'components/DetailsHead';
import Editor from '../../components/Editor';
import { BASE_URL } from '../../api';

const validateMessages = {
  required: "'${label}' is Required!"
};

const { TextArea } = Input;
const { Dragger } = Upload;

const BlogDetails = () => {
  // states
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isDraggerDisable, setIsDraggerDisable] = useState(false);
  const [body, setBody] = useState('');
  const { id } = useParams();
  // navigate
  const navigate = useNavigate();

  // dispatch
  const dispatch = useAppDispatch();

  // form
  const [form] = Form.useForm();

  //selectors
  const loadingById = useAppSelector(selectBlogLoadingById());
  const blogById = useAppSelector(selectBlogById());
  const loadingAction = useAppSelector(selectBlogLoadingAction());

  const onChange: UploadProps['onChange'] = async ({
    fileList: newFileList,
    file
  }) => {
    if (file.status === 'removed') {
      const real_uid = file?.response?.data?.payload[0]?.id;

      await dispatch(deleteFileApi(real_uid ?? file?.uid));

      setFileList([]);
    } else {
      setFileList(newFileList.slice(-1));
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(blogByIdApi(Number(id)));
    }
  }, [dispatch, id]);

  const onClose = () => {
    form.resetFields();
    navigate(-1);
  };

  const onSave = async (values: IBlogByIdValues) => {
    const data = {
      title_en: values.title_en,
      description_en: values.description_en,
      body_en: body
    };

    if (id) {
      await dispatch(blogEdit({ id: Number(id), ...data })).then(() =>
        onClose()
      );
    } else {
      await dispatch(postBlog(data)).then(() => onClose());
    }
  };

  useEffect(() => {
    setIsDraggerDisable(Boolean(fileList.length));
  }, [fileList]);

  useEffect(() => {
    if (id && blogById) {
      form.setFieldsValue({
        title_en: blogById?.title_en,
        description_en: blogById?.description_en
      });
      setBody(blogById.body_en);
      const convertedUrl = convertToAbsoluteUrl(String(BASE_URL), '/public/');
      setFileList(
        blogById.files?.map((item: any) => {
          const url = `${convertedUrl}${item.path}`;

          return {
            uid: item.id,
            name: `${item.name}.${item.extension}`,
            status: 'done',
            url
          };
        }) || []
      );
    }
  }, [blogById, form, id]);
  const customRequest = useCustomRequestForBlogImage({
    url: 'file',
    is_main: false,
    blog_id: id
  });

  return (
    <>
      {loadingById ? (
        <Loading size="large" />
      ) : (
        <>
          <Form
            onFinish={onSave}
            validateMessages={validateMessages}
            size="large"
            form={form}
            layout="vertical"
            scrollToFirstError={{
              behavior: 'smooth',
              block: 'center',
              inline: 'center'
            }}
          >
            <DetailsHead
              titleItem={blogById?.title_en}
              onClose={onClose}
              id={id}
              headTitle={'Blog'}
              form={form}
              loadingAction={loadingAction}
            />
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Title"
                  name="title_en"
                >
                  <Input
                    placeholder="Enter Title in English"
                    maxLength={100}
                    showCount
                  />
                </Form.Item>
                <Form.Item
                  name="description_en"
                  label="Description"
                  rules={[{ required: true }]}
                >
                  <TextArea
                    style={{ height: '150px' }}
                    showCount
                    placeholder="Enter description in English"
                  />
                </Form.Item>
                <Col span={24}>
                  <Form.Item name="files">
                    <Dragger
                      onChange={onChange}
                      fileList={fileList}
                      accept="image/*"
                      customRequest={customRequest}
                      listType="picture"
                      maxCount={1}
                    >
                      <Button
                        disabled={isDraggerDisable}
                        icon={<UploadOutlined />}
                      >
                        {isDraggerDisable
                          ? 'Remove Current Image to Upload New'
                          : 'Upload Image'}
                      </Button>
                    </Dragger>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Text">
                    <Editor setContent={setBody} content={body} />
                  </Form.Item>
                </Col>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </>
  );
};

export default BlogDetails;
