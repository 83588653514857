import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { message } from 'antd';

import { ISliderPostValues, SliderState } from './types';

const initialState: SliderState = {
  sliderById: null,
  loadingByID: false,
  loading: false,
  loadingAction: false,
  images: []
};

export const sliderApi = createAsyncThunk(
  'slider/sliderApi',
  async (_, { rejectWithValue }) => {
    try {
      const res = await client.get('/slider');

      return res.data.data;
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const getSliderById = createAsyncThunk(
  'slider/sliderById',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await client.get(`/slider/${id}`);

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const sliderEdit = createAsyncThunk(
  'slider/sliderEdit',
  async (
    { sliderId, ...data }: ISliderPostValues,
    { dispatch, rejectWithValue }
  ) => {
    try {
      await client.post(`/slider/${sliderId}/files`, { ...data });
      message.success('Slider Images Updated successfully!', 2);

      await dispatch(sliderApi());
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const deleteSliderImage = createAsyncThunk(
  'slider/deleteSliderImage',
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      await client.delete(`/slider/${id}`);
      await dispatch(sliderApi());
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

const sliderSlice = createSlice({
  name: 'slider',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(sliderApi.pending, state => {
        state.loading = true;
      })
      .addCase(sliderApi.fulfilled, (state, action) => {
        state.loading = false;
        state.images = action.payload;
      })
      .addCase(sliderApi.rejected, state => {
        state.loading = false;
      })
      .addCase(getSliderById.pending, state => {
        state.loadingByID = true;
      })
      .addCase(getSliderById.fulfilled, (state, action) => {
        state.sliderById = action.payload;
        state.loadingByID = false;
      })
      .addCase(getSliderById.rejected, state => {
        state.loadingByID = false;
      })
      .addCase(deleteSliderImage.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteSliderImage.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteSliderImage.rejected, state => {
        state.loadingAction = false;
      });
  }
});

export default sliderSlice.reducer;
