import { RootState } from 'app/store';
import { getFileUrl } from 'helpers/file';

import { IFile } from './types';

export const selectFilesData = () => (state: RootState) => state.file.files;

export const selectFileSlider = () => (state: RootState) => {
  const imagesData = selectFilesData()(state);

  return (
    imagesData?.map((item: IFile) => {
      const url = getFileUrl(item.path, true) || '';

      return {
        id: item.id,
        name: `${item.name}`,
        status: 'done',
        url
      };
    }) || []
  );
};

export const selectFileLoading = () => (state: RootState) => state.file.loading;

export const selectFilesSearch = () => (state: RootState) => state.file.search;

export const selectFilesPage = () => (state: RootState) => state.file.page;

export const selectFilesLimit = () => (state: RootState) => state.file.limit;

export const selectFilesCount = () => (state: RootState) => state.file.count;

export const selectFileById = () => (state: RootState) => state.file.fileById;

export const selectFileLoadingAction = () => (state: RootState) =>
  state.file.loadingAction;

export const selectFileLoadingById = () => (state: RootState) =>
  state.file.loadingByID;
