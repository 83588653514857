import axios from 'axios';

const token = localStorage.getItem('token');

export const BASE_URL = process.env.REACT_APP_API_URL;
export const BASE_URL2 = process.env.REACT_APP_API_URL2;

const businessId = 'd4531892-c87c-41af-82f6-78ec7d22c649';
axios.defaults.headers.common['bussinessId'] = businessId;

export const client = axios.create({
  baseURL: BASE_URL,
  headers: {
    'x-access-token': token ? `${token}` : '',
    'Accept-language': 'en'
  }
});

export const setClientToken = (token: string) => {
  client.defaults.headers.common['x-access-token'] = `${token}`;
  client.defaults.headers['x-access-token'] = `${token}`;
};
