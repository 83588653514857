/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useEffect } from 'react';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { RcFile } from 'antd/es/upload';
import { client } from 'api';

type CustomRequestOptions = {
  url: string;
  name?: boolean;
  product_id?: number;
  file_type?: string;
  is_main?: string | boolean;
  blog_id?: any;
  slider_id?: string;
};

const useCustomRequest = ({
  url,
  product_id,
  file_type = '',
  is_main,
  slider_id
}: CustomRequestOptions) => {
  // refs
  const timer = useRef<NodeJS.Timeout | null>(null);
  const queue = useRef<UploadRequestOption<any>[]>([]);

  const customRequest = (e: UploadRequestOption<any>) => {
    queue.current.push(e);

    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(async () => {
      await Promise.all(
        queue.current.map(async item => {
          try {
            const formData = new FormData();
            formData.append(
              'file',
              item.file as Blob,
              (item.file as RcFile).name
            );

            if (product_id) {
              formData.append('product_id', JSON.stringify(product_id));
            }

            if (file_type !== '') {
              formData.append('file_type', file_type);
            }

            if (is_main && slider_id) {
              formData.append('is_main', String(is_main));
              formData.append('slider_id', slider_id);
            }

            const res = await client.post(url, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              onUploadProgress: item.onProgress
            });

            item.onSuccess?.(res);
          } catch (err: any) {
            item.onError?.(err);
          }
        })
      );

      queue.current = [];
    }, 100);
  };

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return customRequest;
};

const useCustomRequestForBlogImage = ({
  url,
  name,
  product_id,
  is_main,
  blog_id
}: CustomRequestOptions) => {
  // refs
  const timer = useRef<NodeJS.Timeout | null>(null);
  const queue = useRef<UploadRequestOption<any>[]>([]);

  const customRequest = (e: UploadRequestOption<any>) => {
    queue.current.push(e);

    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(async () => {
      for (let i = 0; i < queue.current.length; i++) {
        const item = queue.current[i];

        try {
          const formData = new FormData();

          if (product_id) {
            formData.append('product_id', JSON.stringify(product_id));
          }

          formData.append('file', item.file, (item.file as RcFile).name);
          formData.append('is_main', String(is_main));
          formData.append('file_type', 'banner');
          formData.append('blog_id', blog_id);
          name && formData.append('name', (item.file as RcFile).name);

          const res = await client.post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: item.onProgress
          });

          item.onSuccess?.(res);
        } catch (err: any) {
          item.onError?.(err);
        }
      }

      queue.current = [];
    }, 100);
  };

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return customRequest;
};

export { useCustomRequest, useCustomRequestForBlogImage };
