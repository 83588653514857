import styled from 'styled-components';
import { Input } from 'antd';

export const EditableCellValueWrap = styled.div`
  cursor: pointer;
  &:hover {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
`;

export const EditableCountInputWrap = styled(Input)`
  cursor: pointer;
  text-align: center;
  margin-top: 37%;
  &:hover {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
`;

export const CountInputCentered = styled(Input)`
  text-align: center;
  margin-top: 37%;
`;

export const EditablePriceInputWrap = styled(Input)`
  cursor: pointer;
  text-align: center;
  margin-top: 20%;
  &:hover {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
`;

export const PriceInputCentered = styled(Input)`
  text-align: center;
  margin-top: 20%;
`;
