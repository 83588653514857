import { BASE_URL } from 'api';
import { RootState } from 'app/store';

import { ISliderFile } from './types';

export const selectSliderData = () => (state: RootState) => state.slider.images;

export const selectSlider = () => (state: RootState) => {
  const imagesData = selectSliderData()(state);

  return (
    imagesData?.map((item: ISliderFile) => {
      const url = `${BASE_URL}/sliders/${item.name}`;

      return {
        id: item.id,
        name: `${item.name}`,
        status: 'done',
        url
      };
    }) || []
  );
};

export const selectSliderById = () => (state: RootState) =>
  state.slider.sliderById;

export const selectSliderLoadingByID = () => (state: RootState) =>
  state.slider.loadingByID;

export const selectSliderLoading = () => (state: RootState) =>
  state.slider.loading;

export const selectSliderLoadingAction = () => (state: RootState) =>
  state.slider.loadingAction;
