import { useState, useEffect } from 'react';
import { Button, Table, TableColumnsType, Image } from 'antd';
import { DeleteOutlined, EditTwoTone } from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';

import { BASE_URL } from '../../api';
import noImg from '../../assets/img/noimage.png';
import {
  setPage,
  setLimit,
  blogApi,
  deleteBlog
} from '../../app/features/blog/slice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Text, ButtonsWrapper, ImgTableWrraper } from './styled';
import ContentTop from '../../components/ContentTop';
import {
  selectBlogData,
  selectBlogPage,
  selectBlogCount,
  selectBlogLimit,
  selectBlogLoading,
  selectBlogLoadingAction
} from '../../app/features/blog/selectors';
import DeleteModal from '../../components/DeleteModal';
import { convertToAbsoluteUrl } from '../../helpers/utils';

const Blog = () => {
  // state
  const blogData = useAppSelector(selectBlogData());
  const loading = useAppSelector(selectBlogLoading());
  const page = useAppSelector(selectBlogPage());
  const limit = useAppSelector(selectBlogLimit());
  const count = useAppSelector(selectBlogCount());
  const loadingAction = useAppSelector(selectBlogLoadingAction());
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [blogId, setBlogId] = useState<number>(0);

  useEffect(() => {
    dispatch(blogApi());
  }, [dispatch]);

  const sortedItems = blogData
    ? [...blogData].sort((a, b) => {
        const dateA = a.updated_at ? new Date(a.updated_at).getTime() : 0;
        const dateB = b.updated_at ? new Date(b.updated_at).getTime() : 0;

        return dateB - dateA;
      })
    : [];

  const columns: TableColumnsType<any> = [
    {
      title: 'Title',
      dataIndex: 'title_en',
      key: 'title_en'
    },
    {
      title: 'Description',
      dataIndex: 'description_en',
      key: 'description_en',
      render: (text: string) => <Text>{text}</Text>
    },
    {
      title: 'Image',
      dataIndex: 'Files',
      key: 'image',
      width: 100,
      render: (_, item) => {
        const firstImage = item.files[0];

        return (
          <ImgTableWrraper>
            {item.files?.length ? (
              <Image
                height={55}
                width={70}
                alt={firstImage.name}
                src={
                  convertToAbsoluteUrl(String(BASE_URL), '/public/') +
                  firstImage?.path
                }
              />
            ) : (
              <Image preview={false} width={70} src={noImg} alt="No Image" />
            )}
          </ImgTableWrraper>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 220,
      key: 'action',
      render: (_: any, record: any) => (
        <ButtonsWrapper>
          <Link to={`details/${record.id}`}>
            <Button icon={<EditTwoTone />}>Edit</Button>
          </Link>
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
              setBlogId(record.id);
              setOpenDeleteModal(true);
            }}
          >
            Delete
          </Button>
        </ButtonsWrapper>
      )
    }
  ];

  return (
    <>
      <ContentTop
        title="Blog"
        placeholder="Search Blog"
        buttonText="Add Blog"
        onClickAdd={() => navigate('details')}
        searchComponent
        add
      />
      <Table
        bordered
        columns={columns}
        dataSource={sortedItems}
        loading={loading}
        rowKey="id"
        pagination={{
          current: page,
          pageSize: limit,
          total: count,
          onChange: (page, pageSize) => {
            dispatch(setPage(page));
            dispatch(setLimit(pageSize));
            window.scrollTo(0, 0);
          }
        }}
      />
      <DeleteModal
        open={openDeleteModal}
        confirmLoading={loadingAction}
        onOk={() => {
          dispatch(deleteBlog(blogId)).then(() => {
            setOpenDeleteModal(false);
            setBlogId(0);
          });
        }}
        onCancel={() => {
          setOpenDeleteModal(false);
          setBlogId(0);
        }}
      />
    </>
  );
};

export default Blog;
