import { Button, Input, Space, Upload } from 'antd';
import styled from 'styled-components';

export const ContentTopTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ContentTopWrapper = styled.div`
  margin-bottom: 8px;
`;
export const AddButton = styled(Button)`
  margin-top: 8px;
`;
export const SearchStyled = styled(Input.Search)`
  margin-top: 8px;
  max-width: 700px;
`;
export const AddButtonWrapper = styled(Space)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const UploadDraggerFile = styled(Upload.Dragger)`
  .ant-upload-drag {
    margin-top: 8px;
    margin-right: 80px;
    border: none;
    background-color: white;
    .ant-upload {
      padding: 0;
    }
  }
`;
