import { Button, Card } from 'antd';
import styled from 'styled-components';

export const CardImg = styled(Card)`
  .ant-card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-top: 1px solid #f0f0f0;
    text-align: center;
  }
  .ant-card-actions > li {
    margin: 0;
  }
  .ant-card-cover {
    cursor: default;
  }
`;
export const ButtonDelete = styled(Button)`
  .ant-btn {
    border-radius: 0px 0px 6px 6px;
  }
`;

export const Img = styled.img`
  width: 100%;
  height: 98%;
  object-fit: contain;
`;

export const ImgWrraper = styled.div`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 290px;
  }
`;
