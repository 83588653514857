import { Col, Row, Image, message } from 'antd';
// import { deleteSliderImage } from 'app/features/slider/slice';
import { useAppDispatch } from 'app/hooks';
import { FC, useState } from 'react';
// import { DeleteOutlined } from '@ant-design/icons';
import { deleteFile } from 'app/features/files/slice';

import { ButtonDelete, ImgWrraper } from './styled';
import DeleteModal from 'components/DeleteModal';
import Loading from 'components/Loading';
import { ISliderComponentProps } from 'components/SliderPage/types';

const Slider: FC<ISliderComponentProps> = ({
  filesData,
  loading,
  loadingAction
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [imageId, setImageId] = useState<string>('');
  const [itemOpacity, setItemOpacity] = useState('');

  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  // const sliderData = useAppSelector(selectSlider());

  const handleCopy = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    src: string
  ) => {
    event.preventDefault();
    navigator.clipboard.writeText(src);
    message.success(`${src} copied`);
    setItemOpacity(src);
    setTimeout(() => {
      setItemOpacity('');
    }, 2000);
  };

  return (
    <>
      {loading ? (
        <Loading size="large" />
      ) : (
        <>
          <Row gutter={[24, 24]}>
            {filesData?.map((item, index) => {
              return (
                <>
                  <Col
                    style={
                      itemOpacity === item.url
                        ? { opacity: 0.5 }
                        : { opacity: 1 }
                    }
                    key={item.url + index}
                    span={8}
                  >
                    <ImgWrraper onClick={event => handleCopy(event, item.name)}>
                      <Image src={item.url} height={300} width={365}></Image>
                    </ImgWrraper>
                    <ButtonDelete
                      key="delete"
                      style={{ width: '100%', backgroundColor: 'white' }}
                      block
                      // icon={<DeleteOutlined />}
                      danger
                      onClick={() => {
                        setImageId(item.id);
                        setOpenDeleteModal(true);
                      }}
                    >
                      Delete
                    </ButtonDelete>
                  </Col>
                </>
              );
            })}
          </Row>
          <DeleteModal
            open={openDeleteModal}
            confirmLoading={loadingAction}
            onOk={() => {
              dispatch(deleteFile(imageId)).then(() => {
                setOpenDeleteModal(false);
                setImageId('');
              });
            }}
            onCancel={() => {
              setOpenDeleteModal(false);
              setImageId('');
            }}
          />
        </>
      )}
    </>
  );
};

export default Slider;
