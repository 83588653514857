import { DeleteOutlined, DiffTwoTone, EditTwoTone } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Table, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  deleteSection,
  getSectionById,
  sectionApi,
  setLimit,
  setPage,
  setSearch,
  setSectionById
} from 'app/features/sections/slice';
import { ISection } from 'app/features/sections/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { _debounce } from 'helpers/debounce';
import {
  selectSectionCount,
  selectSectionData,
  selectSectionLimit,
  selectSectionLoading,
  selectSectionLoadingAction,
  selectSectionPage,
  selectSectionSearch
} from 'app/features/sections/selectors';
import { useEffect, useState } from 'react';

import ContentTop from 'components/ContentTop';
import { AddProductContainer, ButtonsWrapper } from './styled';
import SectionModal from './components/sectionModal';
import DeleteModal from 'components/DeleteModal';

const debounce = _debounce();

const Sections = () => {
  // states
  const [showSectionModal, setShowSectionModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [sectionId, setSectionId] = useState<number>(0);
  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const loading = useAppSelector(selectSectionLoading());
  const page = useAppSelector(selectSectionPage());
  const count = useAppSelector(selectSectionCount());
  const sectionData = useAppSelector(selectSectionData());
  const search = useAppSelector(selectSectionSearch());
  const limit = useAppSelector(selectSectionLimit());
  const loadingAction = useAppSelector(selectSectionLoadingAction());

  const onEditSection = (id: number) => {
    dispatch(getSectionById(id));
    setShowSectionModal(true);
  };

  useEffect(() => {
    debounce(() => dispatch(sectionApi()));
  }, [dispatch, search, page, limit]);

  const navigate = useNavigate();
  useEffect(() => {
    return () => {
      dispatch(setPage(1));
      dispatch(setSearch(''));
      dispatch(setLimit(10));
    };
  }, [dispatch, navigate]);

  const columns: ColumnsType<ISection> = [
    {
      title: 'Title',
      dataIndex: 'title_en',
      key: 'title_en'
    },

    {
      title: 'Action',
      dataIndex: 'action',
      width: 220,
      key: 'Action',
      render: (_, record) => {
        return (
          <ButtonsWrapper>
            <Link to={`details/${record.id}`}>
              <Button icon={<DiffTwoTone />}>Edit Products </Button>
            </Link>
            <Button
              onClick={() => {
                onEditSection(record.id);
              }}
              icon={<EditTwoTone />}
            >
              Rename Section
            </Button>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                setSectionId(record.id);
                setOpenDeleteModal(true);
              }}
            >
              Delete Section
            </Button>
          </ButtonsWrapper>
        );
      }
    }
  ];

  const onClose = () => {
    dispatch(setSectionById(null));
    setShowSectionModal(false);
  };

  return (
    <>
      <ContentTop
        title="Sections"
        placeholder="Search Section"
        buttonText="Add Section"
        onClickAdd={() => setShowSectionModal(true)}
        onChange={e => {
          dispatch(setSearch(e.target.value));
        }}
        searchComponent
        search={search}
      />
      <AddProductContainer>
        <Button
          type="primary"
          onClick={() => setShowSectionModal(true)}
          size="large"
        >
          Create new section
        </Button>
      </AddProductContainer>
      <Table
        loading={loading}
        pagination={{
          current: page,
          onChange: (page, pageSize) => {
            dispatch(setPage(page));
            dispatch(setLimit(pageSize));
          },
          total: count,
          pageSize: limit
        }}
        bordered
        columns={columns}
        dataSource={sectionData}
        rowKey="id"
      />
      <SectionModal isOpen={showSectionModal} onClose={onClose} />
      <DeleteModal
        open={openDeleteModal}
        confirmLoading={loadingAction}
        onOk={async () => {
          try {
            await dispatch(deleteSection(sectionId));
            setOpenDeleteModal(false);
            setSectionId(-1);
          } catch (error) {
            message.error(
              `An error occurred while deleting the section:, ${error}`
            );
          }
        }}
        onCancel={() => {
          setOpenDeleteModal(false);
          setSectionId(-1);
        }}
      />
    </>
  );
};

export default Sections;
