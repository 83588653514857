import { BASE_URL, BASE_URL2 } from 'api';

export const getFileUrl = (
  path: string,
  // name?: string,
  // extension?: string,
  endPoint?: boolean
) => {
  if (endPoint) {
    return `${BASE_URL?.slice(0, -7)}/public/${path}`;
  } else {
    return `${BASE_URL2?.slice(0, -7)}/public/${path}`;
  }
};
