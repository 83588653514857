import { Typography } from 'antd';
import styled from 'styled-components';

export const Text = styled(Typography.Text)`
  &&& {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ImgTableWrraper = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;
