import { message, Modal, ModalFuncProps } from 'antd';
import { AxiosError } from 'axios';

export type ErrorType = {
  data: null;
  message: string;
  status: number;
};

export const showErrorMessage = (err: AxiosError<ErrorType>) => {
  message.error(err?.response?.data?.message || 'Something went wrong');
};

export const showErrorUploadMessage = (err: AxiosError<ErrorType>) => {
  const errorProps: ModalFuncProps = {
    title: 'Error',
    content: err?.response?.data?.message || 'Something went wrong'
  };

  Modal.error(errorProps);
};
