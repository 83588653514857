import { RootState } from 'app/store';

export const selectBlogData = () => (state: RootState) => state.blog.blog;

export const selectBlogLoading = () => (state: RootState) => state.blog.loading;

export const selectBlogSearch = () => (state: RootState) => state.blog.search;

export const selectBlogPage = () => (state: RootState) => state.blog.page;

export const selectBlogLimit = () => (state: RootState) => state.blog.limit;

export const selectBlogCount = () => (state: RootState) => state.blog.count;

export const selectBlogLoadingAction = () => (state: RootState) =>
  state.blog.loadingAction;

export const selectBlogLoadingById = () => (state: RootState) =>
  state.blog.loadingById;

export const selectBlogById = () => (state: RootState) => state.blog.blogById;
