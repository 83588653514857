import styled from 'styled-components';

// export const ButtonsWrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
// `;

export const AddProductContainer = styled.div`
  width: 100%;
  margin-block: 24px;
  display: flex;
  justify-content: flex-end;
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center; /* Align buttons vertically in the center */

  & > button {
    margin-left: 8px; /* Add margin to each button except the first one */
  }
`;
