import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import {
  selectFilesLimit,
  selectFilesPage,
  selectFilesSearch
} from './selectors';
import {
  //  IFile,
  FileState
} from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initialState: FileState = {
  loading: false,
  loadingAction: false,
  loadingByID: false,
  search: '',
  page: 1,
  limit: 10,
  count: 0,
  files: [],
  fileById: null
};

export const fileApi = createAsyncThunk(
  'files/filesApi',
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const search = selectFilesSearch()(state);
    const page = selectFilesPage()(state);
    const limit = selectFilesLimit()(state);
    const body = { file_type: 'slider' };

    const params = {
      page,
      search,
      limit
    };

    try {
      const res = await client.post(`/file/explore`, body, { params });

      return res.data.payload;
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const getFileById = createAsyncThunk(
  'file/fileById',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await client.get(`/file/${id}`);

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const deleteFile = createAsyncThunk(
  'file/deleteFile',
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      await client.delete(`/file/${id}`);
      await dispatch(fileApi());
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

// export const fileEdit = createAsyncThunk(
//   'file/fileEdit',
//   async ({ id, ...data }: IFile, { dispatch, rejectWithValue }) => {
//     try {
//       await client.patch(`/file/${id}`, {
//         ...data
//       });

//       await dispatch(fileApi());
//     } catch (err) {
//       rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
//     }
//   }
// );

const fileSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    setFile: (state, action) => {
      state.files = action.payload.rows;
      state.count = action.payload.count;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fileApi.pending, state => {
        state.loading = true;
      })
      .addCase(fileApi.fulfilled, (state, action) => {
        state.files = action.payload.rows;
        state.count = action.payload.count;
        state.loading = false;
      })
      .addCase(fileApi.rejected, state => {
        state.loading = false;
      })
      .addCase(getFileById.pending, state => {
        state.loadingByID = true;
      })
      .addCase(getFileById.fulfilled, (state, action) => {
        state.fileById = action.payload;
        state.loadingByID = false;
      })
      .addCase(getFileById.rejected, state => {
        state.loadingByID = false;
      })
      //     .addCase(fileEdit.pending, state => {
      //       state.loadingAction = true;
      //     })
      //     .addCase(fileEdit.fulfilled, state => {
      //       state.loadingAction = false;
      //     })
      //     .addCase(fileEdit.rejected, state => {
      //       state.loadingAction = false;
      //     })
      .addCase(deleteFile.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteFile.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteFile.rejected, state => {
        state.loadingAction = false;
      });
  }
});

export const { setPage, setSearch, setLimit, setFile } = fileSlice.actions;
export default fileSlice.reducer;
