import { Card, Descriptions, Image, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { IProduct } from 'app/features/products/types';
import { getFileUrl } from 'helpers/file';
import { hasContent } from 'helpers/utils';

import noImg from '../../assets/img/noimage.png';

interface ProductPreviewModalProps {
  isPreviewModalVisible: boolean;
  setIsPreviewModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  prod: IProduct | null;
  onCancel: () => void;
}

const ProductPreviewModal: React.FC<ProductPreviewModalProps> = ({
  isPreviewModalVisible,
  prod,
  onCancel
}) => {
  const [product, setProduct] = useState<IProduct | null>(null);

  useEffect(() => {
    setProduct(prod);
  }, [prod]);

  if (!product) {
    return null;
  }

  return (
    <Modal
      destroyOnClose={true}
      title={`${product.title_en}: ${
        product.optowire_price ? `${product.optowire_price} $` : ''
      }`}
      open={isPreviewModalVisible}
      onCancel={onCancel}
      footer={null}
      centered
    >
      <span style={{ marginRight: '2px', fontWeight: 'bold' }}>
        Article number:
      </span>
      <span>{product.articul_number}</span>
      <Card style={{ width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Image
            width={200}
            src={
              product.files.length > 0
                ? getFileUrl(product.files[0].path)
                : noImg
            }
            alt={product.title_en}
          />
        </div>

        <Descriptions title="Product Info" layout="vertical">
          {hasContent(product.html) && (
            <Descriptions.Item label="Description" span={3}>
              <div
                style={{ padding: 10 }}
                dangerouslySetInnerHTML={{ __html: product.html }}
              />
            </Descriptions.Item>
          )}
          {hasContent(product.description1_en) && (
            <Descriptions.Item label="Features" span={3}>
              <div
                style={{ padding: 10 }}
                dangerouslySetInnerHTML={{ __html: product.description1_en }}
              />
            </Descriptions.Item>
          )}
        </Descriptions>
      </Card>
      <span style={{ fontWeight: 'bold' }}>Updated at: </span>
      <span>
        {new Date(product.updated_at).toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          timeZoneName: 'short'
        })}
      </span>
    </Modal>
  );
};

export default ProductPreviewModal;
