import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL2, client } from 'api';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { message } from 'antd';

import {
  selectSectionLimit,
  selectSectionPage,
  selectSectionSearch
} from './selectors';
import { IPostSectionProduct, ISectionById, SectionState } from './types';

const initialState: SectionState = {
  section: [],
  sectionById: null,
  productsBySectionId: null,
  loading: false,
  loadingById: false,
  loadingAction: false,
  page: 1,
  limit: 10,
  search: '',
  count: 0
};

export const sectionApi = createAsyncThunk(
  'section/sectionApi',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as RootState;
    const page = selectSectionPage()(state);
    const search = selectSectionSearch()(state);
    const limit = selectSectionLimit()(state);
    const url = `${BASE_URL2}/section`;

    try {
      const res = await client.get(url, {
        params: {
          search,
          page,
          limit
        }
      });

      return dispatch(setSection(res.data.payload));
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const deleteSection = createAsyncThunk(
  'section/deleteSection',
  async (id: number, { dispatch, rejectWithValue }) => {
    const url = `${BASE_URL2}/section/${id}`;

    try {
      await client.delete(url);

      await dispatch(sectionApi());
      message.success('Section Deleted Successfully');
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const deleteSectionProduct = createAsyncThunk(
  'section/deleteSectionProduct',
  async (
    { sectionId, productId }: { sectionId: number; productId: number[] },
    { dispatch, rejectWithValue }
  ) => {
    const url = `${BASE_URL2}/section/product/${Number(sectionId)}/${Number(
      productId
    )}`;

    try {
      const res = await client.delete(url);

      if (res.status === 200) {
        dispatch(getProductsBySectionId(Number(sectionId)));
        message.success('Product Deleted Successfully');
      }
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const sectionEdit = createAsyncThunk(
  'section/sectionEdit',
  async ({ id, ...data }: ISectionById, { dispatch, rejectWithValue }) => {
    const url = `${BASE_URL2}/section/${id}`;

    try {
      await client.patch(url, {
        ...data
      });

      await dispatch(sectionApi());
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const getSectionById = createAsyncThunk(
  'section/getSectionById',
  async (id: number, { rejectWithValue, dispatch }) => {
    const url = `${BASE_URL2}/section/${id}`;

    try {
      const response = await client.get(url);

      return dispatch(setSectionById(response.data.payload));
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const getProductsBySectionId = createAsyncThunk(
  'section/getProductsBySectionId',
  async (id: number, { rejectWithValue, dispatch }) => {
    const url = `${BASE_URL2}/section/${id}/product`;

    try {
      const response = await client.post(url);

      return dispatch(setProductsBySectionId(response.data.payload));
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const postSection = createAsyncThunk(
  'section/postSection',
  async ({ ...data }: ISectionById, { dispatch, rejectWithValue }) => {
    const url = `${BASE_URL2}/section/create`;

    try {
      await client.post(url, {
        ...data
      });
      await dispatch(sectionApi());
      message.success('Section Created Successfully!');
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);
export const postSectionProduct = createAsyncThunk(
  'section/postSectionProduct',
  async (
    { sectionId, ...data }: IPostSectionProduct,
    { dispatch, rejectWithValue }
  ) => {
    const url = `${BASE_URL2}/section/product/${sectionId}`;

    try {
      const res = await client.post(url, data);

      if (res.status === 200) {
        dispatch(getProductsBySectionId(Number(sectionId)));
        message.success('Product added successfully');
      }
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

const sectionSlice = createSlice({
  name: 'section',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setSection: (state, action) => {
      state.section = action.payload.rows;
      state.count = action.payload.count;
    },
    setSectionById: (state, action) => {
      state.sectionById = action.payload;
    },
    setProductsBySectionId: (state, action) => {
      state.productsBySectionId = action.payload.rows;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(sectionApi.pending, state => {
        state.loading = true;
      })
      .addCase(sectionApi.fulfilled, state => {
        state.loading = false;
      })
      .addCase(sectionApi.rejected, state => {
        state.loading = false;
      })
      .addCase(getSectionById.pending, state => {
        state.loadingById = true;
      })
      .addCase(getSectionById.fulfilled, state => {
        state.loadingById = false;
      })
      .addCase(getSectionById.rejected, state => {
        state.loadingById = true;
      })
      .addCase(getProductsBySectionId.pending, state => {
        state.loadingById = true;
      })
      .addCase(getProductsBySectionId.fulfilled, state => {
        state.loadingById = false;
      })
      .addCase(getProductsBySectionId.rejected, state => {
        state.loadingById = true;
      })
      .addCase(postSection.pending, state => {
        state.loadingAction = true;
      })
      .addCase(postSection.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(postSection.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(deleteSection.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteSection.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteSection.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(sectionEdit.pending, state => {
        state.loadingAction = true;
      })
      .addCase(sectionEdit.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(sectionEdit.rejected, state => {
        state.loadingAction = false;
      });
  }
});

export const {
  setPage,
  setProductsBySectionId,
  setSectionById,
  setSearch,
  setSection,
  setLimit
} = sectionSlice.actions;
export default sectionSlice.reducer;
