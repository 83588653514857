import { Button, Image, Input, Statistic, Table, Tag, Tooltip } from 'antd';
import {
  productApi,
  setLimit,
  setPage,
  setSearch
} from 'app/features/products/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectProductCount,
  selectProductData,
  selectProductLimit,
  selectProductLoading,
  selectProductPage,
  selectProductSearch
} from 'app/features/products/selectors';
import { useEffect, useState } from 'react';
import { _debounce } from 'helpers/debounce';
import { ColumnsType } from 'antd/lib/table';
import { IProduct } from 'app/features/products/types';
import {
  CheckOutlined,
  EyeTwoTone,
  // DeleteOutlined,
  MinusOutlined,
  PercentageOutlined,
  PlusOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { getFileUrl } from 'helpers/file';
import CompanyInfoModal from 'helpers/pdfModal';

import ContentTop from 'components/ContentTop';
import { FalseIcon, IconWrapper, ImgTableWrraper, TrueIcon } from './styled';
import noImg from '../../assets/img/noimage.png';
import { ProductCounts, ProductPrices } from './types';
import ProductPreview from 'pages/ProductDetails';

const debounce = _debounce();

const Products = () => {
  // selectors
  const page = useAppSelector(selectProductPage());
  const productsData = useAppSelector(selectProductData());
  const count = useAppSelector(selectProductCount());
  const loading = useAppSelector(selectProductLoading());
  const search = useAppSelector(selectProductSearch());
  const limit = useAppSelector(selectProductLimit());

  const navigate = useNavigate();

  // state
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<IProduct[]>([]);
  const [productPrices, setProductPrices] = useState<ProductPrices>({});
  const [calculatedPrices, setCalculatedPrices] = useState<ProductPrices>({});
  const [productCounts, setProductCounts] = useState<ProductCounts>({});
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const [prod, setProd] = useState<IProduct | null>(null);

  useEffect(() => {
    const initialProductPrices = productsData.reduce((acc, product) => {
      const productId = product.id;
      const productPrice = Number(product.optowire_price);

      if (!isNaN(productPrice)) {
        acc[productId] = {
          price: productPrice,
          percentage: 0
        };
      }

      return acc;
    }, {} as ProductPrices);

    setCalculatedPrices(initialProductPrices);
    setProductPrices(initialProductPrices);
  }, [productsData]);

  // dispatch
  const dispatch = useAppDispatch();

  const productsWithCounts = selectedProducts.map(product => ({
    product,
    productPrice: calculatedPrices[product.id]?.price,
    quantity: productCounts[product.id] || 1
  }));

  const hasSelected = selectedRowKeys.length > 0;

  const handleIncrementCount = (productId: number) => {
    setProductCounts(prevCounts => ({
      ...prevCounts,
      [productId]: (prevCounts[productId] || 1) + 1
    }));
  };

  const handleDecrementCount = (productId: number) => {
    if (productCounts[productId] > 1) {
      setProductCounts(prevCounts => ({
        ...prevCounts,
        [productId]: prevCounts[productId] - 1
      }));
    }
  };

  const handlePercentageChange = (productId: number, percentage: number) => {
    const productsPrice = productPrices[productId]?.price;
    const updatedPrice = productsPrice + (productsPrice * percentage) / 100;

    setCalculatedPrices(prevPrices => ({
      ...prevPrices,
      [productId]: {
        price: updatedPrice
      }
    }));
  };

  const handlePriceChange = (productId: number, newPrice: number) => {
    setProductPrices(prevPrices => ({
      ...prevPrices,
      [productId]: {
        price: newPrice
      }
    }));
    setCalculatedPrices(prevPrices => ({
      ...prevPrices,
      [productId]: {
        price: newPrice
      }
    }));
  };

  const handleCancelPreview = () => {
    setIsPreviewModalVisible(false);
    setProd(null);
  };

  const openPdfModal = () => {
    setIsModalVisible(true);
  };

  const openPreviewModal = () => {
    setIsPreviewModalVisible(true);
  };

  const closePdfModal = () => {
    setIsModalVisible(false);
  };

  const columns: ColumnsType<IProduct> = [
    {
      title: 'Title',
      dataIndex: 'title_en',
      key: 'title_en',
      width: '25%',
      onCell: (product: IProduct) => ({
        onClick: () => handleRowClick(product)
      })
    },
    {
      title: 'Price',
      dataIndex: 'optowire_price',
      key: 'optowire_price',
      width: '18%',
      align: 'center',
      render: (_, product) => {
        const productId = product.id;
        const calculatedPrice = calculatedPrices[productId]?.price;

        return (
          <>
            <Input
              type="number"
              inputMode="numeric"
              style={{ marginBottom: 2 }}
              placeholder={'Price'}
              value={calculatedPrice}
              onChange={e =>
                handlePriceChange(productId, Number(e.target.value))
              }
            />
            <Input
              onChange={e =>
                handlePercentageChange(productId, Number(e.target.value))
              }
              type="number"
              placeholder={'+ %'}
              addonAfter={
                <>
                  <PlusOutlined />
                  <PercentageOutlined />
                </>
              }
            />
          </>
        );
      }
    },

    {
      title: 'Article number',
      dataIndex: 'articul_number',
      key: 'articul_number',
      align: 'center',
      onCell: (product: IProduct) => ({
        onClick: () => handleRowClick(product)
      })
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
      onCell: (product: IProduct) => ({
        onClick: () => handleRowClick(product)
      })
    },

    {
      title: 'Availability',
      dataIndex: 'availability',
      width: 40,
      align: 'center',
      key: 'availability',
      onCell: (product: IProduct) => ({
        onClick: () => handleRowClick(product)
      }),
      render: value => {
        const color = value ? 'green' : 'red';
        const icon = value ? (
          <TrueIcon style={{ fontSize: 20 }} />
        ) : (
          <FalseIcon style={{ fontSize: 20 }} />
        );

        return (
          <IconWrapper
            style={{ width: 40, height: '100%' }}
            icon={icon}
            key={value}
            color={color}
          ></IconWrapper>
        );
      }
    },
    {
      title: 'Image',
      dataIndex: 'files',
      key: 'image',
      align: 'center',
      width: 100,
      render: files => {
        const thumbnailImageFile =
          files.length > 0
            ? { ...files[0], path: getFileUrl(files[0].path) }
            : null;

        return (
          <ImgTableWrraper>
            {thumbnailImageFile ? (
              <Image
                height={55}
                width={70}
                alt={thumbnailImageFile.name}
                src={thumbnailImageFile.path}
                preview={{
                  src: thumbnailImageFile.path.replace('108x88', '574x438')
                }}
              />
            ) : (
              <Image preview={false} width={70} src={noImg} alt="No Image" />
            )}
          </ImgTableWrraper>
        );
      }
    },
    ...(selectedProducts.length
      ? [
          {
            title: 'Count',
            dataIndex: 'id',
            key: 'count',
            render: (productId: number) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <Button
                    onClick={() => handleIncrementCount(productId)}
                    icon={<PlusOutlined />}
                  />
                  <Statistic value={productCounts[productId] || 1} />
                  <Button
                    onClick={() => handleDecrementCount(productId)}
                    icon={<MinusOutlined />}
                  />
                </div>
              );
            }
          }
        ]
      : []),
    {
      title: 'Action',
      dataIndex: 'action',
      width: 70,
      align: 'center',
      key: 'Action',
      render: (_, record) => {
        return (
          <Tooltip title={'View Product'}>
            <Button
              onClick={() => {
                openPreviewModal();
                setProd(record);
              }}
              size="large"
              icon={<EyeTwoTone />}
            />
          </Tooltip>
        );
      }
    }
  ];

  useEffect(() => {
    debounce(() => dispatch(productApi()));
  }, [dispatch, search, page, limit]);

  useEffect(() => {
    return () => {
      dispatch(setPage(1));
      dispatch(setSearch(''));
      dispatch(setLimit(10));
    };
  }, [dispatch, navigate]);

  const handleRowClick = (product: IProduct) => {
    const isSelected = selectedRowKeys.includes(product.id);
    const newSelectedRowKeys = isSelected
      ? selectedRowKeys.filter(key => key !== product.id)
      : [...selectedRowKeys, product.id];

    setSelectedRowKeys(newSelectedRowKeys);

    const newSelectedProducts = isSelected
      ? selectedProducts.filter(
          selectedProduct => selectedProduct.id !== product.id
        )
      : [...selectedProducts, product];

    setSelectedProducts(newSelectedProducts);
  };

  const onSelect = (product: IProduct) => {
    handleRowClick(product);
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect: onSelect,
    hideSelectAll: true
    // onChange: onSelectChange
  };

  return (
    <>
      <ContentTop
        title="Products"
        placeholder="Search Product"
        onChange={e => {
          dispatch(setSearch(e.target.value));
        }}
        searchComponent
        search={search}
      />

      {selectedProducts.length !== 0 && (
        <div>
          <Button
            onClick={() => openPdfModal()}
            icon={<SettingOutlined />}
            style={{ margin: 10 }}
            type="primary"
            size="large"
          >
            Generate PDF Settings
          </Button>
          <Tag
            color="blue"
            style={{ float: 'right', padding: 8, fontSize: '1em' }}
            icon={<CheckOutlined />}
          >
            <span>
              {hasSelected ? (
                <>
                  <span style={{ marginRight: 8 }}>Selected</span>
                  <span
                    style={{
                      fontWeight: 'bold',
                      fontSize: '1.9em'
                    }}
                  >
                    {selectedRowKeys.length}
                  </span>
                  <span style={{ marginLeft: 8 }}>Product(s)</span>
                </>
              ) : (
                ''
              )}
            </span>
          </Tag>

          <CompanyInfoModal
            productsWithCounts={productsWithCounts}
            visible={isModalVisible}
            onClose={closePdfModal}
          />
        </div>
      )}

      <Table
        loading={loading}
        pagination={{
          current: page,
          onChange: (page, pageSize) => {
            dispatch(setPage(page));
            dispatch(setLimit(pageSize));
          },
          pageSize: limit,
          total: count
        }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={productsData}
        rowKey={record => record.id}
      />
      <ProductPreview
        prod={prod}
        isPreviewModalVisible={isPreviewModalVisible}
        setIsPreviewModalVisible={setIsPreviewModalVisible}
        onCancel={handleCancelPreview}
      />
    </>
  );
};

export default Products;
