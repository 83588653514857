import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client, setClientToken } from 'api';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { AxiosError } from 'axios';

import { AuthState, ILogin } from './types';

const initialState: AuthState = {
  isLoggedIn: !!localStorage.getItem('token'),
  user: null,
  loading: false
};

// export const postLogin = createAsyncThunk(
//   'auth/postLogin',
//   async (values: ILogin, { rejectWithValue }) => {
//     try {
//       const res = await client.post('/auth/login', values);
//       setClientToken(res.data.payload.access_token);
//       localStorage.setItem('token', res.data.payload.access_token);
//     } catch (error) {
//       return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
//     }
//   }
// );

export const postLogin = createAsyncThunk(
  'auth/postLogin',
  async (values: ILogin, { rejectWithValue }) => {
    try {
      const res = await client.post('/auth/login', values);
      setClientToken(res.data.payload.access_token);
      localStorage.setItem('token', res.data.payload.access_token);

      return { success: true };
    } catch (error) {
      return rejectWithValue({
        error: showErrorMessage(error as AxiosError<ErrorType>)
      });
    }
  }
);

export const logOut = createAsyncThunk('auth/logOut', async () => {
  try {
    localStorage.removeItem('token');
    window.location.replace('/login');
  } catch (error) {
    return showErrorMessage(error as AxiosError<ErrorType>);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postLogin.pending, state => {
        state.loading = true;
      })
      .addCase(postLogin.fulfilled, state => {
        state.loading = false;

        state.isLoggedIn = true;
      })
      .addCase(postLogin.rejected, state => {
        state.loading = false;
      })
      .addCase(logOut.fulfilled, state => {
        state.isLoggedIn = false;
      });
  }
});

export default authSlice.reducer;
