import { Typography } from 'antd';
import { FC } from 'react';

import {
  AddButton,
  ContentTopTitle,
  SearchStyled,
  ContentTopWrapper,
  AddButtonWrapper
} from './styled';
import { IContentTopProps } from './types';

const ContentTop: FC<IContentTopProps> = ({
  title,
  placeholder,
  onChange,
  searchComponent,
  search,
  add,
  buttonText,
  onClickAdd
}) => {
  return (
    <ContentTopWrapper>
      <ContentTopTitle>
        <Typography.Title>{title}</Typography.Title>
        {searchComponent && (
          <SearchStyled
            onChange={onChange}
            size="large"
            value={search}
            placeholder={placeholder}
            enterButton
          />
        )}
      </ContentTopTitle>
      {add && (
        <AddButtonWrapper>
          <AddButton size="large" onClick={onClickAdd} type="primary">
            {buttonText}
          </AddButton>
        </AddButtonWrapper>
      )}
    </ContentTopWrapper>
  );
};

export default ContentTop;
