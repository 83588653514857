import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL2, client } from 'api';
import {
  selectProductLimit,
  selectProductPage,
  selectProductSearch
} from 'app/features/products/selectors';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import { ProductState } from './types';

const initialState: ProductState = {
  products: [],
  productById: null,
  loadingAction: false,
  loadingByID: false,
  loading: false,
  page: 1,
  limit: 10,
  search: '',
  count: 0
};

export const productApi = createAsyncThunk(
  'products/productApi',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as RootState;
    const page = selectProductPage()(state);
    const search = selectProductSearch()(state);
    const limit = selectProductLimit()(state);
    const url = `${BASE_URL2}/product/explore`;
    const params = {
      search,
      page,
      limit
    };

    try {
      const res = await client.post(url, null, { params });

      return dispatch(setProduct(res.data.payload));
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const getProductById = createAsyncThunk(
  'products/productById',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await client.get(`${BASE_URL2}/product/${id}`);

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const deleteProduct = createAsyncThunk(
  'products/deleteProduct',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      await client.delete(`${BASE_URL2}/product/${id}`);
      await dispatch(productApi());
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setProduct: (state, action) => {
      state.products = action.payload.rows;
      state.count = action.payload.count;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    }
  },
  extraReducers(builder) {
    builder

      .addCase(productApi.pending, state => {
        state.loading = true;
      })
      .addCase(productApi.fulfilled, state => {
        state.loading = false;
      })
      .addCase(productApi.rejected, state => {
        state.loading = false;
      })
      .addCase(getProductById.pending, state => {
        state.loadingByID = true;
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        state.productById = action.payload;
        state.loadingByID = false;
      })
      .addCase(getProductById.rejected, state => {
        state.loadingByID = false;
      });
  }
});

export const { setPage, setSearch, setProduct, setLimit } =
  productsSlice.actions;
export default productsSlice.reducer;
