import { ArrowLeftOutlined } from '@ant-design/icons';
import { Divider, Input, Typography } from 'antd';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const PageHeader = styled.div`
  padding: 10px;
`;

export const LeftPage = styled(ArrowLeftOutlined)`
  font-size: 17px;
`;

export const PageTitle = styled(Typography.Title)`
  && {
    margin-bottom: 0;
    margin-inline: 20px;
  }
`;
export const SearchStyled = styled(Input.Search)`
  max-width: 700px;
`;

export const HeaderDivider = styled(Divider)`
  margin: 5px 0 50px 0;
`;

export const ImgTableWrraper = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;
export const ModalContent = styled.div`
  background-color: #fff;
  padding: 16px;
  border-radius: 4px;

  p {
    margin: 8px 0;
    font-size: 16px;
  }
`;
