import { ReactNode } from 'react';

export const normalizeDate = (dateString: string) => {
  const date = new Date(dateString);

  return date.toLocaleString();
};

export const hasContent = (content: ReactNode) => Boolean(content);

export function convertToAbsoluteUrl(baseUrl: string, url: string) {
  const base = new URL(baseUrl).origin;
  const convertedUrl = new URL(url, base);

  return convertedUrl.href;
}
