import React from 'react';
import { Modal, Input, Form, Button } from 'antd';
import { IProduct } from 'app/features/products/types';
import { FilePdfOutlined } from '@ant-design/icons';

import generatePDF from './pdfGenerator';

interface IInitialData {
  companyName: string;
  companyAddress: string;
  companyInfo: string;
  termsAndConditionsList: string[];
  bankInformationText: string;
}

interface CompanyInfoModalProps {
  visible: boolean;
  onClose: () => void;
  productsWithCounts: {
    product: IProduct;
    quantity: number;
    productPrice?: number;
  }[];
}

const CompanyInfoModal: React.FC<CompanyInfoModalProps> = ({
  visible,
  onClose,
  productsWithCounts
}) => {
  const initialData: IInitialData = {
    companyName: 'Qingdao Starlink Technologies Co., Ltd',
    companyAddress:
      'ADD: 2 / F, East Office Building, No. 45 Beijing Road, Qianwan Free Trade Port Area,Qingdao, China',
    companyInfo: `Company name: Zhanaidarova LLP
Shipping ways: Land transport
Terms of Delivery: FOB Qingdao, China`,
    termsAndConditionsList: [
      'Payment Terms:TT 30% deposit,70% balance before shipment',
      'Period of validity of quotation: Two weeks',
      'Production lead time:max four Weeks after receive the payment',
      'Warranty shall be 2 years from the date of delivery of the goods'
    ],
    bankInformationText: `Beneficiary Name: Qingdao Starlink Technologies Co., Ltd.
Company address: 2 / F, East Office Building, No. 45 Beijing Road, Qianwan Free Trade Port Area,
Qingdao, China
Beneficiary account number: 3974000050292
Country/Region: HongKong
Swift Code: CITIHKHX or CITIHKHXXXX
Beneficiary Address: 20/F, TOWER ONE, TIMES SQUARE, 1 MATHESON STREET, CAUSEWAY BAY, HONG KONG
Beneficiary Bank: CITIBANK N.A.HONG KONG BRANCH
Beneficiary Bank Address: Champion Tower THREE Garden ROAD CENTRAL, HONG KONG
Bank Code: 006
Branch Code: 391`
  };

  const [form] = Form.useForm();

  const onFinish = async (values: IInitialData) => {
    generatePDF(productsWithCounts, values);
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      width="60%"
      title="Edit Company Information"
      open={visible}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="generatePDF"
          icon={<FilePdfOutlined />}
          type="primary"
          onClick={() => form.submit()}
          size="large"
        >
          Generate and download PDF
        </Button>
      ]}
      onCancel={handleCancel}
    >
      <Form form={form} onFinish={onFinish} initialValues={initialData}>
        <Form.Item label="Company Name" name="companyName">
          <Input />
        </Form.Item>
        <Form.Item label="Company Address" name="companyAddress">
          <Input.TextArea autoSize={{ minRows: 2 }} />
        </Form.Item>
        <Form.Item label="Company Info" name="companyInfo">
          <Input.TextArea autoSize={{ minRows: 3 }} />
        </Form.Item>
        {initialData.termsAndConditionsList.map((_, index) => (
          <Form.Item
            label={`Term ${index + 1}`}
            name={['termsAndConditionsList', index]}
            key={index}
          >
            <Input.TextArea autoSize={{ minRows: 2 }} />
          </Form.Item>
        ))}
        <Form.Item label="Bank Information" name="bankInformationText">
          <Input.TextArea autoSize={{ minRows: 4 }} />
        </Form.Item>
        {/* <Form.Item label="Price" name="price">
          <Input.TextArea autoSize={{ minRows: 4 }} />
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default CompanyInfoModal;
