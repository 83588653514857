export const _debounce = (delay = 400) => {
  let timer: NodeJS.Timeout | null = null;

  return (cb: () => void) => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(cb, delay);
  };
};
