import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL2, client } from 'api';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import { CountryState } from './types';

const initialState: CountryState = {
  loading: false,
  country: []
};

export const countryApi = createAsyncThunk(
  'country/countryApi',
  async (_, { rejectWithValue }) => {
    try {
      const res = await client.get(`${BASE_URL2}/country`);

      return res.data.payload.rows;
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(countryApi.pending, state => {
        state.loading = true;
      })
      .addCase(countryApi.fulfilled, (state, action) => {
        state.loading = false;
        state.country = action.payload;
      })
      .addCase(countryApi.rejected, state => {
        state.loading = false;
      });
  }
});

export default countrySlice.reducer;
