import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectLoginIsLoggedIn } from 'app/features/auth/selectors';
import { useEffect } from 'react';
import { contactApi } from 'app/features/contacts/slice';

import Login from 'pages/Login';
import PageLayout from 'components/PageLayout';
import Products from 'pages/Products';
import Contacts from 'pages/Contacts';
import Sections from 'pages/Sections';
import SectionsDetails from 'pages/SectionsDetails';
import Blog from './pages/Blog';
import BlogDetails from './pages/BlogDetails';

const App = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(selectLoginIsLoggedIn());

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(contactApi(true));
    }
  }, [dispatch, isLoggedIn]);

  return (
    <Router>
      <Routes>
        {isLoggedIn ? (
          <Route path="/" element={<PageLayout />}>
            <Route path="products">
              <Route path="" element={<Products />} />
            </Route>
            <Route path="sections">
              <Route path="" element={<Sections />} />
              <Route path="details/:id" element={<SectionsDetails />} />
              <Route path="details" element={<SectionsDetails />} />
            </Route>
            <Route path="contacts">
              <Route path="" element={<Contacts />} />
            </Route>
            <Route path="blog">
              <Route path="" element={<Blog />} />
              <Route path="details/:id" element={<BlogDetails />} />
              <Route path="details" element={<BlogDetails />} />
            </Route>
          </Route>
        ) : (
          <Route path="/" element={<Login />}>
            <Route path="login" element={<Login />} />
            <Route path="" element={<Navigate to="login" />} />
          </Route>
        )}
      </Routes>
    </Router>
  );
};

export default App;
