import React, { useState } from 'react';
import { LogoutOutlined, SlidersOutlined } from '@ant-design/icons';
import { Button, Layout, Modal, theme, Typography } from 'antd';
import { logOut } from 'app/features/auth/slice';
import { useAppDispatch } from 'app/hooks';
import { Outlet, useNavigate } from 'react-router-dom';

import {
  LogoImgWrapper,
  SiderTop,
  LayoutStyled,
  MenuOutlined1,
  MenuOutlined2,
  ContentStyled,
  HeaderStyled,
  SiderStyled,
  ContentWrapper,
  MenuWrraper
} from './styled';
// import Logo from '../../assets/img/Logo.png';
import MenuSider from 'components/MenuSider';
import SliderPage from 'components/SliderPage';

const { Paragraph } = Typography;

const PageLayout: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [openSlider, setOpenSlider] = useState(false);

  const {
    token: { colorBgContainer }
  } = theme.useToken();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onClickOut = () => {
    Modal.confirm({
      title: 'LogOut',
      content: <Paragraph>Are you sure?</Paragraph>,
      onOk: () => {
        dispatch(logOut());
        // navigate('/login');
      }
    });
  };

  return (
    <LayoutStyled>
      <SliderPage openSlider={openSlider} setOpenSlider={setOpenSlider} />

      <SiderStyled
        trigger={null}
        collapsible
        collapsed={collapsed}
        collapsedWidth={60}
        width={220}
      >
        <SiderTop collapsed={collapsed}>
          {collapsed ? (
            <MenuOutlined2 onClick={() => setCollapsed(!collapsed)} />
          ) : (
            <MenuOutlined1 onClick={() => setCollapsed(!collapsed)} />
          )}
        </SiderTop>
        <MenuSider />
      </SiderStyled>
      <Layout>
        <HeaderStyled>
          <LogoImgWrapper onClick={() => navigate('/')}>
            {/* <LogoImg src={Logo} alt="logo" /> */}
            Optowire Admin Panel
          </LogoImgWrapper>
          <div style={{ display: 'flex' }}>
            <MenuWrraper>
              <Button
                size="large"
                style={{ marginRight: '15px' }}
                icon={<SlidersOutlined />}
                onClick={() => setOpenSlider(true)}
              >
                Slider
              </Button>
            </MenuWrraper>
            <Button size="large" onClick={onClickOut} icon={<LogoutOutlined />}>
              LogOut
            </Button>
          </div>
        </HeaderStyled>
        <ContentWrapper collapsed={collapsed}>
          <ContentStyled bgcolor={colorBgContainer}>
            <Outlet />
          </ContentStyled>
        </ContentWrapper>
      </Layout>
    </LayoutStyled>
  );
};

export default PageLayout;
