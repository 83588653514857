import { Button, Modal, Table, Tabs, Tag, message } from 'antd';
import {
  contactApi,
  getContactById,
  postContact,
  setLimit,
  setPage,
  setSearch
} from 'app/features/contacts/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectContactData,
  selectContactLoading,
  selectContactsCount,
  selectContactsLimit,
  selectContactsPage,
  selectContactsSearch
} from 'app/features/contacts/selectors';
import { useEffect, useState } from 'react';
import { _debounce } from 'helpers/debounce';
import { useNavigate } from 'react-router-dom';
import { ContactId, IContact } from 'app/features/contacts/types';
import {
  DatabaseOutlined,
  ReadOutlined,
  UserSwitchOutlined
} from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

import { ContactModal } from './components';
import ContentTop from 'components/ContentTop';
import { ButtonsWrapper } from './styled';

const debounce = _debounce();

const Contacts = () => {
  const [contactId, setContactId] = useState<string>('');
  const [showContactModal, setShowContactModal] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState<string | null>('1');

  const navigate = useNavigate();
  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const clientData = useAppSelector(selectContactData());
  const loading = useAppSelector(selectContactLoading());
  const page = useAppSelector(selectContactsPage());
  const limit = useAppSelector(selectContactsLimit());
  const count = useAppSelector(selectContactsCount());
  const search = useAppSelector(selectContactsSearch());
  const clients = clientData;
  useEffect(() => {
    debounce(() => dispatch(contactApi(activeTabKey === '1')));
  }, [dispatch, search, page, limit, activeTabKey]);

  useEffect(() => {
    return () => {
      dispatch(setPage(1));
      dispatch(setSearch(''));
      dispatch(setLimit(10));
    };
  }, [dispatch, navigate]);

  const onContactReview = (id: ContactId) => {
    dispatch(getContactById(id));
    setContactId(id);
    setShowContactModal(true);
  };

  const onClose = () => {
    setContactId('');
    setShowContactModal(false);
  };

  const columns: ColumnsType<IContact> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Products',
      dataIndex: 'product_list',
      key: 'product_list',
      width: 80,
      render: item => {
        return (
          <>
            {
              <Tag color={item.length ? 'blue' : 'red'} key={item.id}>
                {item.length ? item.length : 'no products'}
              </Tag>
            }
          </>
        );
      }
    },

    {
      title: 'Action',
      dataIndex: 'action',
      width: 220,
      key: 'Action',
      render: (_, record) => {
        const handleArchive = async (id: string) => {
          Modal.confirm({
            title: 'Do you want to archive this request?',
            onOk: async () => {
              try {
                await dispatch(postContact({ id, active: false }));
              } catch (error) {
                message.error(`Error Archiving Request: ${error}`);
              }
            }
          });
        };

        setContactId(record.id);

        return (
          <ButtonsWrapper>
            <Button
              icon={<ReadOutlined />}
              onClick={() => {
                onContactReview(record.id);
              }}
            >
              Review
            </Button>

            <Button
              danger
              icon={<DatabaseOutlined />}
              onClick={() => {
                handleArchive(record.id);
                // setContactId(record.id);
                // setOpenDeleteModal(true);
              }}
            >
              Archive
            </Button>
          </ButtonsWrapper>
        );
      }
    }
  ];

  const archiveColumns: ColumnsType<IContact> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Products',
      dataIndex: 'product_list',
      key: 'product_list',
      width: 80,
      render: item => {
        return (
          <>
            {
              <Tag color={item.length ? 'blue' : 'red'} key={item.id}>
                {item.length ? item.length : 'no products'}
              </Tag>
            }
          </>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 220,
      key: 'Action',
      render: (_, record) => {
        setContactId(record.id);

        return (
          <ButtonsWrapper>
            <Button
              style={{ width: '100%' }}
              icon={<ReadOutlined />}
              onClick={() => {
                onContactReview(record.id);
              }}
            >
              Review
            </Button>
          </ButtonsWrapper>
        );
      }
    }
  ];

  const tabConfig = [
    {
      key: '1',
      label: 'Active Customer Requests',
      icon: <UserSwitchOutlined style={{ fontSize: 20 }} />,
      content: (
        <Table
          bordered
          columns={columns}
          dataSource={clients.filter(client => client.active)}
          loading={loading}
          rowKey="key"
          pagination={{
            current: page,
            onChange: (page, pageSize) => {
              dispatch(setPage(page));
              dispatch(setLimit(pageSize));
            },
            pageSize: limit,
            total: count
          }}
        />
      )
    },
    {
      key: '2',
      label: 'Archived customer requests',
      icon: <DatabaseOutlined style={{ fontSize: 20 }} />,
      content: (
        <Table
          bordered
          columns={archiveColumns}
          dataSource={clients.filter(client => !client.active)}
          loading={loading}
          rowKey="key"
          pagination={{
            current: page,
            onChange: (page, pageSize) => {
              dispatch(setPage(page));
              dispatch(setLimit(pageSize));
            },
            pageSize: limit,
            total: count
          }}
        />
      )
    }
  ];

  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  return (
    <>
      <ContentTop
        title="Customer Requests"
        placeholder="Search Customer Requests"
        // onChange={e => {
        //   dispatch(setSearch(e.target.value));
        // }}
        // searchComponent
        // search={search}
      />
      <Tabs
        onChange={onTabChange}
        type="card"
        items={tabConfig.map(tab => ({
          key: tab.key,
          label: tab.label,
          tab: (
            <span>
              {activeTabKey === tab.key ? tab.icon : tab.icon}
              {tab.label}
            </span>
          ),
          children: tab.content
        }))}
      />
      <ContactModal
        isOpen={showContactModal}
        onClose={onClose}
        params={{
          contactId
        }}
      />
    </>
  );
};

export default Contacts;
