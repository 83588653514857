import {
  // AppstoreAddOutlined,
  // AppstoreOutlined,
  BoldOutlined,
  ShoppingOutlined,
  UnorderedListOutlined,
  // UsergroupDeleteOutlined,
  UserSwitchOutlined
} from '@ant-design/icons';
import { Menu, Badge } from 'antd';
import { selectContactsCount } from 'app/features/contacts/selectors';
import { contactApi } from 'app/features/contacts/slice';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const MenuSider = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(contactApi(true));
  }, [dispatch]);

  const { pathname } = useLocation();

  const count = useAppSelector(selectContactsCount());

  return (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={[pathname.split('/')[1]]}
      defaultSelectedKeys={[pathname.split('/')[1]]}
      items={[
        // {
        //   key: 'categories',
        //   icon: <AppstoreOutlined style={{ fontSize: 20 }} />,
        //   label: <Link to="categories">Categories</Link>
        // },
        {
          key: 'products',
          icon: <ShoppingOutlined style={{ fontSize: 20 }} />,
          label: <Link to="products">Products</Link>
        },
        {
          key: 'sections',
          icon: <UnorderedListOutlined style={{ fontSize: 20 }} />,
          label: <Link to="sections">Sections</Link>
        },
        // {
        //   key: 'attributes',
        //   icon: <AppstoreAddOutlined style={{ fontSize: 20 }} />,
        //   label: <Link to="attributes">Attributes</Link>
        // },
        {
          key: 'contacts',
          icon: (
            <Badge count={count} size="small">
              <UserSwitchOutlined style={{ fontSize: 20 }} />
            </Badge>
          ),
          label: <Link to="contacts">Customer Requests</Link>
        },
        {
          key: 'blog',
          icon: <BoldOutlined style={{ fontSize: 20 }} />,
          label: <Link to="blog">Blog</Link>
        }
      ]}
    />
  );
};

export default MenuSider;
