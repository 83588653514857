import { Image, Space, Table, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { IProduct } from 'app/features/products/types';
import {
  selectProductCount,
  selectProductData,
  selectProductLimit,
  selectProductLoading,
  selectProductPage,
  selectProductSearch
} from 'app/features/products/selectors';
import {
  productApi,
  setLimit,
  setPage,
  setSearch
} from 'app/features/products/slice';
import { useNavigate, useParams } from 'react-router-dom';
import { _debounce } from 'helpers/debounce';
import {
  getProductsBySectionId,
  getSectionById,
  sectionApi,
  setSectionById
} from 'app/features/sections/slice';
import {
  selectProductsBySectionId,
  selectSectionById
} from 'app/features/sections/selectors';
import { getFileUrl } from 'helpers/file';

import {
  HeaderContainer,
  HeaderDivider,
  ImgTableWrraper,
  LeftPage,
  // ModalContent,
  PageHeader,
  PageTitle,
  SearchStyled
} from './styled';
import noImg from '../../assets/img/noimage.png';
import SectionSwitch from 'pages/Sections/components/SectionSwitch';

const debounce = _debounce();

const SectionsAddProduct = () => {
  const productData = useAppSelector(selectProductData());
  const page = useAppSelector(selectProductPage());
  const count = useAppSelector(selectProductCount());
  const loading = useAppSelector(selectProductLoading());
  const search = useAppSelector(selectProductSearch());
  const limit = useAppSelector(selectProductLimit());
  const sectionByIdData = useAppSelector(selectSectionById());
  const productsBySectionId = useAppSelector(selectProductsBySectionId());
  const [activeTab, setActiveTab] = useState<string>('1');

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onTabChange = (key: string) => {
    setActiveTab(key);
  };

  useEffect(() => {
    debounce(() => dispatch(productApi()));
  }, [dispatch, search, page, limit]);

  useEffect(() => {
    dispatch(sectionApi());
    dispatch(getSectionById(Number(id)));
    dispatch(getProductsBySectionId(Number(id)));
  }, [dispatch, id, navigate]);

  useEffect(() => {
    return () => {
      dispatch(setPage(1));
      dispatch(setSearch(''));
      dispatch(setLimit(10));
    };
  }, [dispatch, navigate]);

  const columns: ColumnsType<IProduct> = [
    {
      title: 'Title',
      dataIndex: 'title_en',
      key: 'title_en',
      width: 300
    },
    {
      title: 'Article number',
      dataIndex: 'articul_number',
      key: 'articul_number',
      width: 250
    },
    {
      title: 'Image',
      dataIndex: 'files',
      key: 'image',
      align: 'center',
      width: 100,
      render: files => {
        const thumbnailImageFile =
          files.length > 0
            ? { ...files[0], path: getFileUrl(files[0].path) }
            : null;

        return (
          <ImgTableWrraper>
            {thumbnailImageFile ? (
              <Image
                height={55}
                width={70}
                alt={thumbnailImageFile.name}
                src={thumbnailImageFile.path}
                preview={{
                  src: thumbnailImageFile.path.replace('108x88', '574x438')
                }}
              />
            ) : (
              <Image preview={false} width={70} src={noImg} alt="No Image" />
            )}
          </ImgTableWrraper>
        );
      }
    },
    {
      title: 'Updated at',
      dataIndex: 'updated_at',
      key: 'updated_at',
      align: 'center',
      width: 230,
      render: value => {
        return (
          <div>
            <span>
              {new Date(value).toLocaleString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
              })}
            </span>
          </div>
        );
      }
    },
    {
      title: 'Add Products To',
      align: 'center',
      children: [
        {
          align: 'center',
          title: sectionByIdData?.title_en,
          dataIndex: sectionByIdData?.title_en,
          render: (_, record) => {
            return (
              <SectionSwitch
                productId={[record.id]}
                sectionId={sectionByIdData?.id}
                sectionProducts={productsBySectionId || []}
              />
            );
          }
        }
      ]
    }
  ];

  const productDataTabContent = (
    <>
      <Table
        columns={columns}
        loading={loading}
        pagination={{
          current: page,
          onChange: (page, pageSize) => {
            dispatch(setPage(page));
            dispatch(setLimit(pageSize));
          },
          pageSize: limit,
          total: count
        }}
        scroll={{ x: 1100 }}
        bordered
        dataSource={productData}
        rowKey="id"
      />
    </>
  );

  const sectionProductsTabContent = (
    <>
      <Table
        columns={columns}
        loading={loading}
        scroll={{ x: 1100 }}
        bordered
        dataSource={productsBySectionId || []}
        rowKey="id"
      />
    </>
  );

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Products List',
      children: productDataTabContent
    },
    {
      key: '2',
      label: 'Section Products',
      children: sectionProductsTabContent
    }
  ];

  return (
    <>
      <PageHeader>
        <HeaderContainer>
          <Space>
            <LeftPage
              onClick={() => {
                navigate(-1);
                dispatch(setSectionById(null));
              }}
            />
            <PageTitle level={2}>Edit products list</PageTitle>
          </Space>
          <SearchStyled
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(setSearch(e.target.value));
            }}
            placeholder="Search Product"
            enterButton
          />
        </HeaderContainer>
      </PageHeader>
      <HeaderDivider />
      <Tabs activeKey={activeTab} items={items} onChange={onTabChange} />
    </>
  );
};

export default SectionsAddProduct;
