import { Modal, Button, Image, Tooltip, Form } from 'antd';
import { useAppSelector } from 'app/hooks';
import {
  selectContactLoadingAction,
  selectContactById,
  selectContactLoadingById
} from 'app/features/contacts/selectors';
import { FC, memo, useEffect, useState } from 'react';
import { ContactProduct } from 'app/features/contacts/types';
import { getFileUrl } from 'helpers/file';
import CompanyInfoModal from 'helpers/pdfModal';
import { CopyTwoTone, SettingFilled } from '@ant-design/icons';
import Table, { ColumnsType } from 'antd/es/table';
import { cleanText } from 'helpers/pdfGenerator';
import { handleCopy } from 'helpers/handleCopy';
import { normalizeDate } from 'helpers/utils';

import { IContactModalProps } from './types';
import {
  ClientInfoItem,
  ClientInfoWrapper,
  CreatedAtItem
} from 'pages/Contacts/styled';
import noImg from '../../../../assets/img/noimage.png';
import {
  CountInputCentered,
  EditableCountInputWrap,
  EditablePriceInputWrap,
  PriceInputCentered
} from './styled';
import { ImgTableWrraper } from 'pages/Products/styled';

const ContactModal: FC<IContactModalProps> = ({ isOpen, onClose }) => {
  const [clientProducts, setClientProducts] = useState<ContactProduct[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingKey, setEditingKey] = useState<number | null>(null);
  const [editingKeyPrice, setEditingKeyPrice] = useState<number | null>(null);
  const [form] = Form.useForm();

  const isEditing = (record: ContactProduct) =>
    record.product.id === editingKey;

  const isEditingPrice = (record: ContactProduct) =>
    record.product.id === editingKeyPrice;

  const contactById = useAppSelector(selectContactById());
  const loading = useAppSelector(selectContactLoadingById());

  useEffect(() => {
    if (contactById && contactById.product_list !== undefined) {
      const productsWithCounts: ContactProduct[] =
        contactById.product_list?.map(item => ({
          product: item.product,
          quantity: item.quantity,
          productPrice: item.product.optowire_price
        }));

      setClientProducts(productsWithCounts);
      contactById.product_list.map(item => {
        form.setFieldsValue({
          quantity: item.quantity,
          productPrice: item.productPrice
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactById]);

  const loadingAction = useAppSelector(selectContactLoadingAction());

  const updateProductField = (
    productId: number,
    field: 'quantity' | 'productPrice',
    value: string
  ) => {
    form.setFieldsValue({
      [productId]: {
        [field]: value
      }
    });

    setClientProducts(prevProducts => {
      return prevProducts.map(product =>
        product.product.id === productId
          ? { ...product, [field]: Number(value) }
          : product
      );
    });
  };

  const editCount = (record: ContactProduct) => {
    form.setFieldsValue({
      quantity: record.quantity
    });
    setEditingKey(record.product.id);
  };

  const editPrice = (record: ContactProduct) => {
    form.setFieldsValue({
      productPrice: record.productPrice
    });
    setEditingKeyPrice(record.product.id);
  };

  const saveCount = async (productId: number, value: string) => {
    updateProductField(productId, 'quantity', value);
    setEditingKey(null);
  };

  const savePrice = async (productId: number, value: string) => {
    updateProductField(productId, 'productPrice', value);
    setEditingKeyPrice(null);
  };

  const handleCloseModal = () => {
    onClose();
    setClientProducts([]);
  };

  const modalTitle = () => 'Review Contact';

  const openPdfModal = () => {
    setIsModalVisible(true);
  };

  const closePdfModal = () => {
    setIsModalVisible(false);
  };

  const columns: ColumnsType<ContactProduct> = [
    {
      title: 'Title',
      dataIndex: 'product',
      key: 'title',
      onCell: record => ({
        onClick: () => {
          const titleText = record.product.title_en;
          handleCopy(titleText);
        }
      }),
      render: (_, item) => (
        <Tooltip title="Copy Title" placement="top">
          <span style={{ cursor: 'pointer' }}>
            <CopyTwoTone style={{ float: 'right' }} />
            {item.product.title_en}
          </span>
        </Tooltip>
      )
    },
    {
      title: 'Article Number',
      onCell: record => ({
        onClick: () => {
          const titleText = record.product.articul_number;
          handleCopy(titleText);
        }
      }),
      key: 'articleNumber',
      render: (_, item) => (
        <Tooltip title="Copy Article Number" placement="top">
          <span style={{ cursor: 'pointer' }}>
            <CopyTwoTone style={{ float: 'right' }} />
            {item.product.articul_number}
          </span>
        </Tooltip>
      )
    },
    {
      title: 'Description',
      align: 'center',
      key: 'articleNumber',
      render: (_, item) => {
        const Description = cleanText(item.product?.description1_en)
          .replace(/●/g, '\n')
          .replace(/[1-5]\)/g, '\n');

        return Description.replace(/^\s*[\r\n]/gm, '');
      }
    },
    {
      title: 'Image',
      dataIndex: 'product',
      align: 'center',
      key: 'image',
      render: product => {
        const thumbnailImageFile =
          product.files.length > 0
            ? { ...product.files[0], path: getFileUrl(product.files[0].path) }
            : null;

        return (
          <ImgTableWrraper>
            {thumbnailImageFile ? (
              <Image
                height={55}
                width={70}
                alt={thumbnailImageFile.name}
                src={thumbnailImageFile.path}
                preview={{
                  src: thumbnailImageFile.path.replace('108x88', '574x438')
                }}
              />
            ) : (
              <Image preview={false} width={70} src={noImg} alt="No Image" />
            )}
          </ImgTableWrraper>
        );
      }
    },
    {
      title: 'QTY',
      dataIndex: 'quantity',
      align: 'center',
      key: 'quantity',
      render: (_, record) => {
        const editable = isEditing(record);
        const productId = record.product.id;

        return editable ? (
          <Form.Item name={[productId, 'quantity']}>
            <CountInputCentered
              onBlur={e =>
                saveCount(productId, (e.target as HTMLInputElement).value)
              }
              onPressEnter={e =>
                saveCount(productId, (e.target as HTMLInputElement).value)
              }
              autoFocus
            />
          </Form.Item>
        ) : (
          <Form.Item
            initialValue={record.quantity}
            name={[productId, 'quantity']}
          >
            <EditableCountInputWrap
              bordered={false}
              onClick={() => editCount(record)}
            />
          </Form.Item>
        );
      }
    },
    {
      title: 'Unit',
      // dataIndex: 'address',
      align: 'center',
      key: 'unit',
      render: (_, item) => <p>{item.product.unit}</p>
    },
    {
      title: 'Price',
      dataIndex: 'productPrice',
      align: 'center',
      width: '15%',
      key: 'productPrice',
      render: (_, record) => {
        const editable = isEditingPrice(record);

        return editable ? (
          <Form.Item name={[record.product.id, 'productPrice']}>
            <PriceInputCentered
              onBlur={e =>
                savePrice(
                  record.product.id,
                  (e.target as HTMLInputElement).value
                )
              }
              onPressEnter={e =>
                savePrice(
                  record.product.id,
                  (e.target as HTMLInputElement).value
                )
              }
              autoFocus
            />
          </Form.Item>
        ) : (
          <Form.Item
            initialValue={record.productPrice}
            name={[record.product.id, 'productPrice']}
          >
            <EditablePriceInputWrap
              bordered={false}
              onClick={() => editPrice(record)}
            />
          </Form.Item>
        );
      }
    }
  ];

  return (
    <Modal
      title={modalTitle()}
      open={isOpen}
      onCancel={handleCloseModal}
      width={'70%'}
      footer={[
        <Button
          style={{ float: 'left' }}
          onClick={handleCloseModal}
          key="Cancel"
        >
          Cancel
        </Button>,
        <Button
          icon={<SettingFilled />}
          type="dashed"
          onClick={openPdfModal}
          key="GeneratePdf"
          loading={loadingAction}
        >
          Generate PDF Prefrences
        </Button>
      ]}
    >
      <ClientInfoWrapper>
        <ClientInfoItem>
          <strong>Full name:</strong> {contactById?.name}
        </ClientInfoItem>
        <ClientInfoItem>
          <strong>Email Address:</strong> {contactById?.email}
        </ClientInfoItem>
        <ClientInfoItem>
          <strong>Phone Number:</strong> {contactById?.phone}
        </ClientInfoItem>
        {contactById?.message && (
          <ClientInfoItem>
            <strong>Customer Message:</strong> {contactById?.message}
          </ClientInfoItem>
        )}
        <CreatedAtItem>
          <strong>Created At:</strong>{' '}
          {normalizeDate(contactById?.created_at || '')}
        </CreatedAtItem>
        {contactById?.product_list?.length !== undefined &&
          contactById?.product_list?.length > 0 && (
            <Form form={form}>
              <Table
                columns={columns}
                dataSource={clientProducts}
                bordered
                loading={loading}
                pagination={false}
              />
            </Form>
          )}
      </ClientInfoWrapper>
      <CompanyInfoModal
        productsWithCounts={clientProducts}
        visible={isModalVisible}
        onClose={closePdfModal}
      />
    </Modal>
  );
};

export default memo(ContactModal);
