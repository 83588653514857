import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import {
  selectContactsLimit,
  selectContactsPage,
  selectContactsSearch
} from './selectors';
import {
  //  IContact,
  ContactState,
  IContactPost
} from './types';
import { logOut } from '../auth/slice';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initialState: ContactState = {
  loading: false,
  loadingAction: false,
  loadingByID: false,
  search: '',
  page: 1,
  limit: 10,
  count: 0,
  contacts: [],
  contactById: null
};

export const contactApi = createAsyncThunk(
  'contacts/contactApi',
  async (
    newOrArchived: boolean | undefined,
    { getState, rejectWithValue, dispatch }
  ) => {
    const state = getState() as RootState;
    const search = selectContactsSearch()(state);
    const page = selectContactsPage()(state);
    const limit = selectContactsLimit()(state);
    const body = { active: newOrArchived } ?? '';
    // const body = options?.body || '';

    const params = {
      page,
      search,
      limit
    };

    try {
      const res = await client.post('contact/explore', body, { params });

      return res.data.payload;
    } catch (error) {
      if (error instanceof AxiosError && error.response?.data?.code === 401) {
        dispatch(logOut());
      }

      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);
// export const productApi = createAsyncThunk(
//   'products/productApi',
//   async (
//     options: ProductApiOptions | undefined,
//     { getState, dispatch, rejectWithValue }
//   ) => {
//     const state = getState() as RootState;
//     const page = options?.page || selectProductPage()(state);
//     const search = options?.search || selectProductSearch()(state);
//     const limit = options?.limit || selectProductLimit()(state);
//     const body = options?.body || '';
//     const params = {
//       page,
//       search,
//       limit
//     };

//     try {
//       const res = await client.post(`/product/explore`, body, { params });

//       return dispatch(setProduct(res.data.payload));
//     } catch (error) {
//       return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
//     }
//   }
// );

export const getContactById = createAsyncThunk(
  'contact/contactById',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await client.get(`/contact/get/${id}`);

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const postContact = createAsyncThunk(
  'contact/postContact',
  async ({ id, active }: IContactPost, { dispatch, rejectWithValue }) => {
    try {
      await client.post(`/contact/update/${id}`, {
        active
      });
      await dispatch(contactApi());
    } catch (err) {
      return rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

// export const contactEdit = createAsyncThunk(
//   'contact/contactEdit',
//   async ({ id, ...data }: IContact, { dispatch, rejectWithValue }) => {
//     try {
//       await client.patch(`/contact/${id}`, {
//         ...data
//       });

//       await dispatch(contactApi());
//     } catch (err) {
//       rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
//     }
//   }
// );

const contactSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setContact: (state, action) => {
      state.contacts = action.payload.rows;
      state.count = action.payload.count;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(contactApi.pending, state => {
        state.loading = true;
      })
      .addCase(contactApi.fulfilled, (state, action) => {
        state.contacts = action.payload.rows;
        state.count = action.payload.count;
        state.loading = false;
      })
      .addCase(contactApi.rejected, state => {
        state.loading = false;
      })
      .addCase(getContactById.pending, state => {
        state.loadingByID = true;
      })
      .addCase(getContactById.fulfilled, (state, action) => {
        state.contactById = action.payload;
        state.loadingByID = false;
      })
      .addCase(getContactById.rejected, state => {
        state.loadingByID = false;
      });
    //     .addCase(postContact.pending, state => {
    //       state.loadingAction = true;
    //     })
    //     .addCase(postContact.fulfilled, state => {
    //       state.loadingAction = false;
    //     })
    //     .addCase(postContact.rejected, state => {
    //       state.loadingAction = false;
    //     })
    //     .addCase(contactEdit.pending, state => {
    //       state.loadingAction = true;
    //     })
    //     .addCase(contactEdit.fulfilled, state => {
    //       state.loadingAction = false;
    //     })
    //     .addCase(contactEdit.rejected, state => {
    //       state.loadingAction = false;
    //     })
    //     .addCase(deleteContact.pending, state => {
    //       state.loadingAction = true;
    //     })
    //     .addCase(deleteContact.fulfilled, state => {
    //       state.loadingAction = false;
    //     })
    //     .addCase(deleteContact.rejected, state => {
    //       state.loadingAction = false;
    //     });
  }
});

export const { setPage, setSearch, setLimit, setContact } =
  contactSlice.actions;
export default contactSlice.reducer;
