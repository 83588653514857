import { Layout } from 'antd';
import styled from 'styled-components';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

export const LayoutStyled = styled(Layout)`
  min-height: 100vh;
`;
export const MenuOutlined1 = styled(MenuFoldOutlined)`
  color: white;
  font-size: 25px;
`;
export const MenuOutlined2 = styled(MenuUnfoldOutlined)`
  color: white;
  font-size: 25px;
`;

export const LogoImgWrapper = styled.div`
  max-width: 500px;
  height: 70px;
  cursor: pointer;
  font-size: 45px;
  font-family: cursive;
  font-weight: 900;
`;
export const SiderTop = styled.div<{ collapsed: boolean }>`
  display: flex;
  width: 100%;
  justify-content: ${props => (props.collapsed ? 'center' : 'end')};
  padding: 10px;
  align-items: center;
  height: 64px;
`;
export const LogoImg = styled.img`
  width: 100%;
`;

export const ContentStyled = styled(Layout.Content)<{ bgcolor: string }>`
  &&& {
    margin: 24px;
    padding: 24px;
    border-radius: 15px;
    background: ${props => props.bgcolor};
    min-height: calc(100vh - 112px);
  }
`;
export const HeaderStyled = styled(Layout.Header)`
  && {
    display: flex;
    padding-inline: 24px;
    justify-content: space-between;
    align-items: center;
    background: hsla(0, 0%, 100%, 0.8);
    backdrop-filter: saturate(180%) blur(5px);
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 5;
  }
`;
export const SiderStyled = styled(Layout.Sider)`
  && {
    height: 100vh;
    position: fixed;
    left: 0;
    top: 64px;
  }
`;
export const ContentWrapper = styled.div<{ collapsed: boolean }>`
  width: ${props => props.collapsed && 'calc(100% - 50px)'};
  margin-left: ${props => (props.collapsed ? '50px' : '220px')};
  transition: all 0.3s;
`;
export const MenuWrraper = styled.div`
  display: flex;
`;
