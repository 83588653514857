import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  Drawer,
  Select,
  Space,
  Image,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
  message,
  Form,
  Col
} from 'antd';
import { CheckOutlined, UploadOutlined } from '@ant-design/icons';
import { useCustomRequest } from 'Hook/useCustomRequest';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  getSliderById,
  sliderApi,
  sliderEdit
} from 'app/features/slider/slice';
import { selectSliderById } from 'app/features/slider/selectors';
import {
  selectFileLoading,
  selectFileLoadingAction,
  selectFileSlider
  // selectFileLoading,
  // selectFileLoadingAction
} from 'app/features/files/selectors';
import { getFileUrl } from 'helpers/file';
import { fileApi } from 'app/features/files/slice';
import { ISliderPostValues } from 'app/features/slider/types';

import { ISliderFileList, ISliderPageProps } from './types';
import {
  ContentTopTitle,
  ContentTopWrapper,
  DrawerStyled,
  UploadWrraper
} from './styled';
import Slider from './components/Slider';

const { Dragger } = Upload;
const { Title } = Typography;
const { Option } = Select;

const SliderPage: FC<ISliderPageProps> = ({ openSlider, setOpenSlider }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [sliderFileList, setSliderFileList] = useState<ISliderFileList[]>([]);
  const [openAddImage, setOpenAddImage] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const sliderId = '402339b3-bb9b-41ca-9793-e4f5804e55dc';
  //dispatch
  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  // selectors
  useEffect(() => {
    dispatch(getSliderById(sliderId));
  }, [dispatch]);
  const filesData = useAppSelector(selectFileSlider());
  const loading = useAppSelector(selectFileLoading());
  const loadingAction = useAppSelector(selectFileLoadingAction());

  useEffect(() => {
    dispatch(sliderApi());
    dispatch(fileApi());
  }, [dispatch]);

  const sliderById = useAppSelector(selectSliderById());

  useEffect(() => {
    if (sliderById) {
      setSliderFileList(
        sliderById.files.map(item => {
          // const url = getFileUrl(item.name, item.extension, 'sliders');
          const url = getFileUrl(item.path, true) || '';
          // const url = getFileUrl(item.path, 'slider') || '';

          return {
            id: item.id,
            name: `${item.name}`,
            url
          };
        })
      );
    }
  }, [sliderById]);

  const onChange: UploadProps['onChange'] = async ({
    fileList: newFileList
  }) => {
    setFileList(newFileList);

    const isAllUploaded = newFileList.every(file => file.status === 'done');

    if (isAllUploaded) {
      setFileList([]);
      await message.success('Pictures uploaded successfully!', 2);
      setOpenAddImage(false);
      await dispatch(fileApi());
    }
  };

  const customRequest = useCustomRequest({
    url: '/file',
    is_main: 'false',
    file_type: 'slider',
    slider_id: sliderId
  });

  const onFinish = async (values: ISliderPostValues) => {
    setSubmitLoading(true);

    const data = {
      // name: 'slider',
      files: values.files
    };

    // try {
    await dispatch(sliderEdit({ sliderId, ...data }));
    setSubmitLoading(false);
    // } catch (error) {
    // await message.success(`${error}Failed To Update file list`, 3);
    // }
  };

  return (
    <>
      <Drawer
        title="Add Image To Slider"
        placement="right"
        onClose={() => setOpenAddImage(false)}
        open={openAddImage}
        width={700}
      >
        <Form onFinish={onFinish} form={form}>
          <Form.Item name="files">
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Add Slider Images"
              defaultValue={sliderFileList?.map(file => file.name)}
              optionLabelProp="label"
            >
              {filesData?.map(item => (
                <Option key={item.id} value={item.id} label={item.name}>
                  <Space>
                    <span role="img" aria-label={item.name}>
                      <Image width={100} src={item.url} />
                    </span>
                    {item.name}
                  </Space>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Col span={6} offset={20}>
            <Button
              type="primary"
              icon={<CheckOutlined />}
              loading={submitLoading}
              onClick={form.submit}
            >
              Submit
            </Button>
          </Col>
        </Form>
      </Drawer>
      <DrawerStyled
        title={
          <ContentTopWrapper>
            <ContentTopTitle>
              <Title level={3}>All Files</Title>
            </ContentTopTitle>
            <UploadWrraper>
              <Dragger
                onChange={onChange}
                accept="image/*"
                listType="picture"
                multiple
                fileList={fileList}
                customRequest={customRequest}
              >
                <Button icon={<UploadOutlined />}>Upload Image</Button>
              </Dragger>
            </UploadWrraper>
            <Button
              style={{ marginTop: '6px' }}
              type="primary"
              onClick={() => setOpenAddImage(true)}
            >
              Add Image
            </Button>
          </ContentTopWrapper>
        }
        placement="right"
        onClose={() => setOpenSlider(false)}
        open={openSlider}
        width={1200}
      >
        <Slider
          filesData={filesData}
          loading={loading}
          loadingAction={loadingAction}
        />
      </DrawerStyled>
    </>
  );
};

export default SliderPage;
