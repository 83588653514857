import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ImgTableWrraper = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;
export const ClientInfoWrapper = styled.div`
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #333;
  margin-top: 20px;
  text-align: center;
  margin: 2%;
`;

export const ClientInfoItem = styled.p`
  strong {
    font-weight: bold;
  }
  margin: 1%;
`;
export const CreatedAtItem = styled.p`
  strong {
    font-weight: bold;
  }
  float: right;
  margin: 1%;
`;
