import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL2, client } from 'api';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import {
  selectCategoryLimit,
  selectCategoryPage,
  selectCategorySearch
} from './selectors';
import { CategoryState } from './types';

const initialState: CategoryState = {
  category: [],
  categoryById: null,
  loading: false,
  loadingAction: false,
  loadingById: false,
  count: 0,
  search: '',
  page: 1,
  limit: 10
};

export const categoryApi = createAsyncThunk(
  'category/categoryApi',
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const search = selectCategorySearch()(state);
    const page = selectCategoryPage()(state);
    const limit = selectCategoryLimit()(state);

    const params = {
      page,
      search,
      limit
    };

    try {
      const res = await client.post(`${BASE_URL2}/category/explore`, _, {
        params
      });

      return res.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const categoryProductApi = createAsyncThunk(
  'category/categoryProductApi',
  async (id: number, { rejectWithValue }) => {
    try {
      const res = await client.post(`${BASE_URL2}/category/${id}/product`);

      return res.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const filterApi = createAsyncThunk(
  'category/filterApi',
  async (_, { rejectWithValue }) => {
    try {
      const res = await client.get('${BASE_URL2}/category/filter');

      return res.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const getCategoryById = createAsyncThunk(
  'category/categoryById',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await client.get(`${BASE_URL2}/category/${id}`);

      return response.data.payload;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(categoryApi.pending, state => {
        state.loading = true;
      })
      .addCase(categoryApi.fulfilled, (state, action) => {
        state.category = action.payload.rows;
        state.count = action.payload.count;
        state.loading = false;
      })
      .addCase(categoryApi.rejected, state => {
        state.loading = false;
      })
      .addCase(categoryProductApi.fulfilled, (state, action) => {
        state.category = action.payload.rows;
      })
      .addCase(getCategoryById.pending, state => {
        state.loadingById = true;
      })
      .addCase(getCategoryById.fulfilled, (state, action) => {
        state.categoryById = action.payload;
        state.loadingById = false;
      })
      .addCase(getCategoryById.rejected, state => {
        state.loadingById = false;
      });
  }
});

export const { setPage, setSearch, setLimit } = categorySlice.actions;
export default categorySlice.reducer;
