import { useRef } from 'react';
import JoditEditor from 'jodit-react';

//import { IEditorProps } from './types';

const Editor = ({ content, setContent }: any) => {
  const editor = useRef(null);
  const editorConfig = {
    zIndex: 0,
    readonly: false,
    activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about', 'dots'],
    theme: 'default',
    saveModeInCookie: false,
    spellcheck: true,
    triggerChangeEvent: true,
    width: 'auto',
    height: 'auto',
    debugLanguage: false,
    tabIndex: -1,
    toolbar: true,
    useSplitMode: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    events: {},
    textIcons: false,
    removeButtons: [],
    disablePlugins: [],
    extraButtons: [],
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    buttons: [
      '|',
      'bold',
      '|',
      'strikethrough',
      '|',
      'underline',
      '|',
      'italic',
      '|',
      'superscript',
      '|',
      'subscript',
      '|',
      'font',
      '|',
      'fontsize',
      '|',
      'table',
      '|',
      'lineHeight',
      '|',
      'ul',
      '|',
      'ol',
      '|',
      'brush',
      '|',
      'paragraph',
      '|',
      'hr',
      '|',
      'symbols',
      '|',
      'cut',
      '|',
      'copy',
      '|',
      'outdent',
      '|',
      'indent',
      '|',
      {
        name: 'left',
        default: 'left',
        list: ['left', 'center', 'right', 'justify']
      },
      '|',
      'link',
      '|',
      'image',
      '|',
      'video',
      '|',
      'selectall',
      '|',
      'find',
      '|',
      'undo',
      '|',
      'redo',
      '|',
      'preview',
      '|',
      'fullsize',
      '|'
    ]
  };

  return (
    <div>
      <JoditEditor
        config={editorConfig}
        value={content}
        ref={editor}
        onBlur={e => {
          setContent(e);
        }}
      />
    </div>
  );
};

export default Editor;
