import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import {
  deleteSectionProduct,
  postSectionProduct
} from 'app/features/sections/slice';
import { useAppDispatch } from 'app/hooks';
import { FC, useEffect, useState } from 'react';

import { EditStates, ISectionSwitchProps } from 'pages/SectionsDetails/types';

const SectionSwitch: FC<ISectionSwitchProps> = ({
  productId,
  sectionId,
  sectionProducts
}) => {
  const [switchState, setSwitchState] = useState<EditStates>({});

  //dispatch
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (sectionProducts) {
      const initialSwitchState: EditStates = {};
      sectionProducts.forEach(product => {
        initialSwitchState[product.id] = product.id === productId[0];
      });
      setSwitchState(initialSwitchState);
    }
  }, [productId, sectionProducts]);

  const handleSwitchChange = (isChecked: boolean) => {
    const data = {
      product_ids: [...productId]
    };

    if (isChecked !== switchState[productId[0]]) {
      setSwitchState(prevState => ({
        ...prevState,
        [productId[0]]: !prevState[productId[0]]
      }));

      if (isChecked) {
        dispatch(
          postSectionProduct({
            sectionId,
            ...data
          })
        );
      } else if (!isChecked && sectionId) {
        dispatch(
          deleteSectionProduct({
            sectionId,
            productId
          })
        );
      }
    }
  };

  return (
    <Switch
      style={{ width: '50%' }}
      checked={switchState[productId[0]] ? true : false}
      onChange={handleSwitchChange}
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
    />
  );
};

export default SectionSwitch;
