import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import blogSlice from './features/blog/slice';
import authSlice from './features/auth/slice';
import categorySlice from './features/category/slice';
import productsSlice from './features/products/slice';
import countrySlice from './features/country/slice';
import sliderSlice from './features/slider/slice';
import fileSlice from './features/files/slice';
import sectionSlice from './features/sections/slice';
import contactSlice from './features/contacts/slice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    category: categorySlice,
    products: productsSlice,
    country: countrySlice,
    slider: sliderSlice,
    file: fileSlice,
    section: sectionSlice,
    contact: contactSlice,
    blog: blogSlice
  }
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
