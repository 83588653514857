import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { IProduct } from 'app/features/products/types';

import { getFileUrl } from './file';
import img from '.././assets/img/image.png';
import noImg from '../../src/assets/img/noimage.png';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const getImageDataUrl = async (url: string): Promise<string> => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();

    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.result) {
          resolve(reader.result.toString());
        } else {
          reject('Failed to load the image.');
        }
      };

      reader.onerror = () => reject('Error reading the image.');
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    throw new Error(`Failed to fetch image: ${error}`);
  }
};

export const cleanText = (html: string) => {
  if (typeof DOMParser !== 'undefined') {
    const doc = new DOMParser().parseFromString(html, 'text/html');

    return doc.body.textContent || '';
  }

  return html.replace(/<[^>]*>/g, '');
};

export const generatePDF = async (
  productsWithCounts: {
    product: IProduct;
    quantity: number;
    productPrice?: number;
  }[],
  initialData: {
    companyName: string;
    companyAddress: string;
    companyInfo: string;
    termsAndConditionsList: string[];
    bankInformationText: string;
  }
) => {
  const invoiceNumber = new Date()
    .toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    })
    .replace(/\D/g, '');

  const imagePromises = productsWithCounts.map(async item => {
    const firstImageFile = item.product.files[0];

    const imagePath = firstImageFile?.path || '';
    const imageUrl = item.product.files[0] ? getFileUrl(imagePath) : noImg;

    return await getImageDataUrl(imageUrl as string);
  });

  const imageUrls = await Promise.all(imagePromises);

  const sealImage = await getImageDataUrl(img as string);

  const tableData = [
    [
      { text: 'S.N', alignment: 'center' },
      { text: 'Item', alignment: 'center' },
      { text: 'Description Of Product', alignment: 'center' },
      { text: 'Image', alignment: 'center' },
      { text: 'QTY', alignment: 'center' },
      { text: 'Unit', alignment: 'center' },
      { text: 'USD/Price', alignment: 'center' },
      { text: 'USD/Total Price', alignment: 'center' }
    ],
    ...productsWithCounts.map((item, index) => {
      const Description = cleanText(item.product?.description1_en)
        .replace(/●/g, '\n')
        .replace(/[1-5]\)/g, '\n');

      const price = item?.productPrice;
      const totalPrice =
        item.quantity > 1 ? String(Number(price) * item.quantity) : price;

      return [
        { text: (index + 1).toString(), alignment: 'center' },
        { text: item.product.title_en, alignment: 'center' },
        {
          text: Description.replace(/^\s*[\r\n]/gm, ''),
          alignment: 'center',
          fontSize: 10
        },
        { image: imageUrls[index], fit: [50, 50], alignment: 'center' },
        { text: String(item.quantity), alignment: 'center' },
        { text: item.product.unit, alignment: 'center' },
        { text: price, alignment: 'center' },
        { text: Number(totalPrice)?.toFixed(2), alignment: 'center' }
      ];
    })
  ];

  const docDefinition: TDocumentDefinitions = {
    content: [
      {
        text: initialData.companyName ?? '',
        fontSize: 16,
        bold: true,
        alignment: 'center',
        margin: [0, 0, 0, 10]
      },
      {
        text: initialData.companyAddress ?? '',
        fontSize: 11,
        alignment: 'center',
        margin: [0, 0, 0, 10]
      },
      {
        text: 'Commercial Invoice',
        fontSize: 14,
        bold: true,
        alignment: 'center',
        margin: [0, 0, 0, 30]
      },
      {
        text: initialData.companyInfo ?? '',
        fontSize: 11,
        alignment: 'left',
        margin: [20, 0, 0, 20]
      },
      {
        text: `Date: ${new Date().toLocaleDateString()}`,
        fontSize: 11,
        alignment: 'right',
        margin: [0, 0, 5, 2]
      },
      {
        text: `Invoice NO: ${invoiceNumber}`,
        fontSize: 11,
        alignment: 'right',
        margin: [0, 0, 3, 10]
      },
      {
        table: {
          headerRows: 1,
          widths: [
            'auto',
            'auto',
            'auto',
            'auto',
            'auto',
            'auto',
            'auto',
            'auto'
          ],
          body: tableData
        }
      },
      {
        text: '(1). Terms & Conditions:',
        fontSize: 11,
        bold: true,
        margin: [0, 20, 0, 10]
      },
      {
        ul: initialData.termsAndConditionsList ?? '',
        fontSize: 11,
        margin: [0, 0, 0, 10]
      },
      {
        text: '(2). Bank Information:',
        fontSize: 11,
        bold: true,
        margin: [0, 10, 0, 10]
      },
      {
        text: initialData.bankInformationText ?? '',
        fontSize: 11,
        margin: [0, 0, 0, 10]
      },
      {
        absolutePosition: { x: 350, y: 670 },
        image: sealImage,
        width: 400,
        margin: [0, 0]
      },
      {
        absolutePosition: { x: 425, y: 785 },
        text: '  Signature: Herman Lee',
        fontSize: 11
      },
      {
        text: 'I DECLARE THAT THE ABOVE INFORMATION IS TRUE AND CORRECT TO THE BEST OF MY KNOWLEDGE',
        fontSize: 11
      },
      {
        text: 'REASON FOR EXPORT : Used in optical communication project',
        fontSize: 11
      },
      {
        text: 'Buyer :',
        marginTop: 4,
        fontSize: 11
      }
    ]
  };

  pdfMake.createPdf(docDefinition).open();
};

export default generatePDF;
