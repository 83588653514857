import { message } from 'antd';

export const handleCopy = (
  src: string,
  event?: React.MouseEvent<HTMLDivElement, MouseEvent>
) => {
  event?.preventDefault();
  navigator.clipboard.writeText(src);
  message.success(`${src} Copied to clipboard`);
};
