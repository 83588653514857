import { RootState } from 'app/store';

export const selectContactData = () => (state: RootState) =>
  state.contact.contacts;

export const selectContactLoading = () => (state: RootState) =>
  state.contact.loading;

export const selectContactsSearch = () => (state: RootState) =>
  state.contact.search;

export const selectContactsPage = () => (state: RootState) =>
  state.contact.page;

export const selectContactsLimit = () => (state: RootState) =>
  state.contact.limit;

export const selectContactsCount = () => (state: RootState) =>
  state.contact.count;

export const selectContactById = () => (state: RootState) =>
  state.contact.contactById;

export const selectContactLoadingAction = () => (state: RootState) =>
  state.contact.loadingAction;

export const selectContactLoadingById = () => (state: RootState) =>
  state.contact.loadingByID;
